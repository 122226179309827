// -----------------------------------------------------------------------------
// Document search, people list pages.
// -----------------------------------------------------------------------------

@use "../base/breakpoints";
@use "../base/container";
@use "../base/fonts";
@use "../base/spacing";
@use "../base/typography";

main.search *,
main.people * {
    max-width: none;
    @include breakpoints.for-tablet-landscape-up {
        max-width: 56rem; // = 896px
    }
}
main.search,
main.people {
    padding: 0 1rem;
    @include breakpoints.for-tablet-landscape-up {
        padding: 0;
    }
    // Header
    & > h1,
    .topheader-row h1 {
        font-family: fonts.$primary;
        font-size: typography.$text-size-5xl;
        @include breakpoints.for-tablet-landscape-up {
            font-size: typography.$text-size-7xl;
        }
    }
    & > h1,
    .topheader-row {
        margin-bottom: 1rem;
    }
}

main.search > h1 {
    width: 100%;
}
