// -----------------------------------------------------------------------------
// Home page styles
// -----------------------------------------------------------------------------

@use "../base/breakpoints";
@use "../base/spacing";
@use "../base/typography";

main.homepage {
    // Header text with background
    h1 {
        color: transparent;
        z-index: 1;
        margin-top: -5.5rem;
        padding: 100px spacing.$spacing-3xl 130px;
        max-width: 100%;
        width: 100%;
        text-align: center;
        @include breakpoints.for-tablet-landscape-up {
            margin-top: -6rem;
            padding: 285px 0 180px;
        }
    }
    .container {
        line-height: 1.4;
    }
}

// Homepage background, light mode, mobile
@mixin light-home {
    background: url("/static/img/ui/mobile/light/all/banner-pgp-text.svg") 70%
            33% no-repeat,
        linear-gradient(
            to top,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 1) 17px,
            rgba(255, 255, 255, 0.17) 66%,
            rgba(255, 255, 255, 0)
        ),
        url("/static/img/ui/mobile/light/all/banner-fragment.svg") 70% 0px
            no-repeat,
        linear-gradient(
            180deg,
            rgba(86, 120, 86, 0.2) 0%,
            rgba(86, 120, 86, 0.112) 51.9%,
            rgba(86, 120, 86, 0) 100%
        );

    // Homepage background, light mode, desktop
    @include breakpoints.for-tablet-landscape-up {
        background: url("/static/img/ui/desktop/light/all/banner-pgp-text-left.svg")
                52% 39% no-repeat,
            linear-gradient(
                to top,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0.41) 35.28%,
                rgba(255, 255, 255, 0) 100%
            ),
            url("/static/img/ui/desktop/light/all/banner-pgp-text-right.svg")
                52% 39% no-repeat,
            url("/static/img/ui/desktop/light/all/banner-fragment.svg") 52% 90%
                no-repeat,
            linear-gradient(
                180deg,
                rgba(86, 120, 86, 0.2) 0%,
                rgba(86, 120, 86, 0.112) 51.9%,
                rgba(86, 120, 86, 0) 100%
            );
    }
}

// Homepage background, dark mode, mobile
@mixin dark-home {
    background: url("/static/img/ui/mobile/dark/all/banner-pgp-text.svg") 70%
            33% no-repeat,
        linear-gradient(
            to top,
            rgba(22, 22, 22, 1),
            rgba(22, 22, 22, 0.41) 35.28%,
            rgba(22, 22, 22, 0) 100%
        ),
        url("/static/img/ui/mobile/dark/all/banner-fragment.svg") 70% 0px
            no-repeat,
        linear-gradient(
            180deg,
            rgba(195, 127, 151, 0.2) 0%,
            rgba(195, 127, 151, 0.112) 51.9%,
            rgba(195, 127, 151, 0) 100%
        );

    // Homepage background, dark mode, desktop
    @include breakpoints.for-tablet-landscape-up {
        background: url("/static/img/ui/desktop/dark/all/banner-pgp-text-left.svg")
                52% 39% no-repeat,
            linear-gradient(
                to top,
                rgba(22, 22, 22, 1) 0%,
                rgba(22, 22, 22, 0.12) 30.95%,
                rgba(22, 22, 22, 0) 100%
            ),
            url("/static/img/ui/desktop/dark/all/banner-pgp-text-right.svg") 52%
                39% no-repeat,
            url("/static/img/ui/desktop/dark/all/banner-fragment.svg") 52% 90%
                no-repeat,
            linear-gradient(
                180deg,
                rgba(195, 127, 151, 0.2) 0%,
                rgba(195, 127, 151, 0.2) 74.18%,
                rgba(195, 127, 151, 0) 100%
            );
    }
}

// Applying background image and gradient per system theme
main.homepage h1 {
    @include light-home;
    @media (prefers-color-scheme: dark) {
        @include dark-home;
    }
}

// Manual (body-class based) theme overrides
html.light-mode main.homepage h1 {
    @include light-home;
}
html.dark-mode main.homepage h1 {
    @include dark-home;
}
