// -----------------------------------------------------------------------------
// Place list/browse page.
// -----------------------------------------------------------------------------

@use "../base/a11y";
@use "../base/breakpoints";
@use "../base/colors";
@use "../base/fonts";
@use "../base/spacing";
@use "../base/typography";

// TODO: standardize typography here once all redesigns are implemented

// 100vh - header - footer
$content-height-desktop: calc(100vh - 8rem - 3rem);

html:has(main.places) {
    max-height: 100vh;
    overflow: hidden;
    .wagtail-userbar-nav {
        display: none !important;
    }
}
html:has(#mobile-mode-toggle input:checked) {
    overflow-y: scroll;
    @include breakpoints.for-tablet-landscape-up {
        overflow: hidden;
    }
}
#main:has(main.places) {
    @include breakpoints.for-tablet-landscape-up {
        flex: 1 0 auto;
    }
}
main.places,
main.places * {
    max-width: none;
}
main.places {
    padding: 0 1rem;
    overflow: visible;
    @include breakpoints.for-tablet-landscape-up {
        padding: 0;
        margin-top: -6rem;
        margin-bottom: -4.5rem;
        height: $content-height-desktop;
        max-height: $content-height-desktop;
    }
    form {
        @include breakpoints.for-tablet-landscape-up {
            display: grid;
            grid-template-columns: 1fr 404px;
            overflow: hidden;
            width: 100vw;
        }
    }
    section#map-container {
        display: block;
        width: 100%;
        position: absolute;
        bottom: -7px;
        height: calc(100vh - 10rem);
        z-index: 2;
        @include breakpoints.for-tablet-landscape-up {
            position: relative;
            height: auto;
            bottom: auto;
            z-index: auto;
        }
        #map {
            display: block;
            width: 100%;
            height: 100%;
        }
        a {
            text-underline-offset: auto;
            text-decoration-thickness: 1px;
        }
        svg.marker {
            color: var(--map-pin-disabled-outline);
            fill: var(--map-pin-disabled);
            width: 22px;
            height: 28px;
            cursor: pointer;
            .selected-pin {
                display: none;
            }
            &.selected-place {
                filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.33));
                color: var(--primary);
                fill: var(--tertiary);
                width: 33px;
                height: 42px;
                z-index: 2;
                transition: width 0.3s ease-out, height 0.3s ease-out,
                    filter 0.3s ease-out;
                @include breakpoints.for-tablet-landscape-up {
                    width: 42px;
                    height: 48px;
                }
                .pin {
                    display: none;
                }
                .selected-pin {
                    display: block;
                }
            }
        }
        .maplibregl-ctrl-top-right {
            @include breakpoints.for-tablet-landscape-up {
                margin: 5rem 2.5rem 0 0;
            }
        }
        .maplibregl-ctrl-bottom-right {
            display: none;
            @include breakpoints.for-tablet-landscape-up {
                display: block;
                margin: 0 2rem 3.5rem 0;
            }
        }
    }
    // Primary container
    section#place-list {
        padding: 0 1rem;
        margin-top: -2.5rem;
        width: 100vw;
        max-width: 100vw;
        @include breakpoints.for-tablet-landscape-up {
            margin-top: 0;
            width: 100%;
            padding: 4rem 2rem 3.5rem;
            max-height: 100%;
            height: 100%;
            overflow-y: scroll;
        }
        // Header
        .header-row {
            margin-bottom: 1rem;
            gap: 0.5rem;
            align-items: baseline;
            h1 {
                flex: 0 1 auto;
                font-family: fonts.$primary;
                font-size: typography.$text-size-5xl;
                @include breakpoints.for-tablet-landscape-up {
                    font-size: typography.$text-size-7xl;
                }
            }
            h1 + span {
                flex: 1 0 auto;
            }
            fieldset#sort-field {
                display: none;
                @include breakpoints.for-tablet-landscape-up {
                    display: flex;
                }
            }
            details summary span {
                overflow: hidden;
                white-space: nowrap;
                max-width: 100px;
                text-overflow: ellipsis;
            }
            div#sort-options {
                z-index: 2;
            }
            .mobile-only {
                @include breakpoints.for-tablet-landscape-up {
                    display: none;
                }
            }
        }

        // list of places
        & > ul {
            position: absolute;
            padding: 1rem 1rem 0;
            left: 0;
            width: 100vw;
            max-width: none;
            max-height: calc(100vh - 11.25rem);
            @include breakpoints.for-tablet-landscape-up {
                position: relative;
                width: 100%;
                max-width: 100%;
                max-height: none;
                padding: 0;
            }
            li.place {
                width: 100%;
                background-color: var(--background-light);
                border: 1px solid var(--background-gray);
                transition: border 0.3s ease-in-out;
                padding: 1rem;
                scroll-margin-top: 8rem;
                border-radius: 5px;
                &.selected-place {
                    max-width: calc(100vw - 2rem);
                    position: absolute;
                    z-index: 4;
                    bottom: 1rem;
                    left: 1rem;
                    top: auto;
                    box-shadow: 0px 2px 4px 0px #00000040;
                    @include breakpoints.for-tablet-landscape-up {
                        border: 3px solid var(--primary);
                        max-width: auto;
                        box-shadow: none;
                        position: relative;
                        z-index: auto;
                        bottom: auto;
                        left: auto;
                    }
                }
                & + li.place {
                    margin-top: 1rem;
                }
                dl.metadata {
                    padding-bottom: 0.5rem;
                    .name {
                        @include typography.body-bold;
                        a {
                            font-family: fonts.$primary-bold;
                        }
                    }
                    .other-names {
                        @include typography.meta;
                    }
                }
                dl.relations {
                    display: grid;
                    grid-template-columns: 1fr 5%;
                    border-top: 1px solid colors.$gray;
                    padding-top: 0.5rem;
                    dt {
                        font-size: typography.$text-size-sm;
                        font-family: fonts.$primary-italic;
                        font-style: italic;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 0.5rem;
                        @include breakpoints.for-tablet-landscape-up {
                            font-size: typography.$text-size-md;
                        }
                        &::before {
                            font-style: normal;
                            @include typography.icon-button-sm;
                        }
                        &.documents::before {
                            content: "\edf2";
                        }
                        &.people::before {
                            content: "\f346";
                        }
                        &.places::before {
                            content: "\f20d";
                        }
                    }
                    dd {
                        text-align: center;
                        @include typography.meta;
                    }
                    svg {
                        width: 15px;
                        height: 16px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
    .pagination-container {
        text-align: center;
        margin: 1rem 0 0;
        nav.pagination {
            margin: 1rem 0;
        }
    }
    #mobile-mode-toggle {
        position: absolute;
        display: flex;
        align-items: center;
        background-color: var(--primary);
        color: var(--on-primary);
        z-index: 3;
        bottom: 2rem;
        padding: 0.5rem 1rem;
        border-radius: 50px;
        gap: 0.33rem;
        border: none;
        outline: none;
        left: 50%;
        transform: translateX(-50%);
        @include typography.body-bold;
        &::before {
            content: "\f20f"; // phosphor ph-map-trifold icon
            @include typography.icon-button-sm;
            font-weight: normal;
        }
        input {
            @include a11y.sr-only;
        }
        input ~ #map-mode-label,
        input:checked ~ #list-mode-label {
            display: none;
        }
        input:checked ~ #map-mode-label {
            display: block;
        }
        &:has(input:checked) {
            position: fixed;
        }
        @include breakpoints.for-tablet-landscape-up {
            display: none;
        }
    }
    // list mode on mobile (map hidden)
    &:has(#mobile-mode-toggle input:checked) {
        section#map-container {
            display: none;
            @include breakpoints.for-tablet-landscape-up {
                display: block;
            }
        }
        section#place-list {
            & > ul {
                position: relative;
                width: 100%;
                max-width: 100%;
                max-height: none;
                padding: 0;
            }
            fieldset#sort-field {
                display: flex;
            }
        }
        .pagination-container {
            margin-bottom: 6rem;
        }
    }
}

html[dir="rtl"] main.places {
    @include breakpoints.for-tablet-landscape-up {
        div.header-row fieldset#sort-field details div#sort-options {
            right: auto;
            left: 0;
            ul:first-of-type input {
                margin: 0 0 0.2rem 0.5rem;
            }
        }
    }
}
