// -----------------------------------------------------------------------------
// Project fonts, including font face declarations and font stacks.
// -----------------------------------------------------------------------------

@charset "UTF-8";

// Usage counter and license for myfonts

// !!! TODO: enable counter, but only in production !!!
// @import url("//hello.myfonts.net/count/4098e1");

/**
 * @license
 * MyFonts Webfont Build ID 4233441, 2021-12-03T12:11:09-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FrankRuhl1924MF-Medium-Medium by Masterfont
 * URL: https://www.myfonts.com/fonts/masterfont/frank-ruhl1924-mf/frank-ruhl1924-mf/
 * Copyright: Masterfont Ltd.
 *
 *
 *
 * © 2021 MyFonts Inc
*/

// @font-face declarations

@mixin declare-fonts($base_url) {
    // Greta Sans regular for Latin
    @font-face {
        font-family: "Greta Sans Regular";
        font-style: normal;
        src: url("#{$base_url}/WF-037420-011914-001035.woff2") format("woff2"),
            url("#{$base_url}/WF-037420-011914-001035.woff") format("woff");
        font-display: swap;
    }

    // Fallback Greta Sans for Hebrew
    @font-face {
        font-family: "Greta Sans Hebrew Regular";
        src: url("#{$base_url}/GretaSansH-Regular.woff2") format("woff2");
        font-style: normal;
        font-weight: normal;
        font-display: fallback;
    }

    // Greta Sans Bold for Latin
    @font-face {
        font-family: "Greta Sans Regular Bold";
        font-style: normal;
        font-weight: bold;
        src: url("#{$base_url}/WF-037420-011914-001041.woff2") format("woff2"),
            url("#{$base_url}/WF-037420-011914-001041.woff") format("woff");
        font-display: swap;
    }

    // Greta Sans Semibold for Latin
    @font-face {
        font-family: "Greta Sans Regular Semibold";
        font-style: normal;
        font-weight: 600;
        src: url("#{$base_url}/WF-037420-012177-002520.woff2") format("woff2"),
            url("#{$base_url}/WF-037420-012177-002520.woff") format("woff");
        font-display: swap;
    }

    // Fallback Greta Sans Bold for Hebrew
    @font-face {
        font-family: "Greta Sans Hebrew Bold";
        src: url("#{$base_url}/GretaSansH-Bold.woff2") format("woff2");
        font-style: normal;
        font-weight: bold;
        font-display: fallback;
    }

    // Greta Sans italic for Latin text
    @font-face {
        font-family: "Greta Sans Regular Italic";
        font-style: italic;
        src: url("#{$base_url}/WF-037420-011914-001036.woff2") format("woff2"),
            url("#{$base_url}/WF-037420-011914-001036.woff") format("woff");
        font-display: swap;
    }

    // HassanLTLight for Arabic text
    @font-face {
        font-family: "HassanLTLight";
        font-style: normal;
        size-adjust: 125%; // Arabic text will be 125% of Latin text size
        font-weight: normal;
        src: url("#{$base_url}/HassanLTLight.woff2") format("woff2"),
            url("#{$base_url}/HassanLTLight.woff") format("woff");
        font-display: swap;
    }

    // HassanLTBold for Arabic text
    @font-face {
        font-family: "HassanLTBold";
        font-style: normal;
        size-adjust: 125%; // Arabic text will be 125% of Latin text size
        font-weight: bold;
        src: url("#{$base_url}/HassanLTBold.woff2") format("woff2"),
            url("#{$base_url}/HassanLTBold.woff") format("woff");
        font-display: swap;
    }

    // Frank Ruhl1924 MF Medium for Hebrew text (transcriptions)
    @font-face {
        font-family: "FrankRuhl 1924 MF Medium";
        font-style: normal;
        src: url("#{$base_url}/FrankRuhl1924MF-Medium-Medium.woff2")
                format("woff2"),
            url("#{$base_url}/FrankRuhl1924MF-Medium-Medium.woff")
                format("woff");
        font-display: swap;
        unicode-range:
            // Hebrew
            U+0590-05FF,
            // Hebrew presentation forms
            U+FB1D-FB4F;
    }

    // Amiri for Arabic text (transcriptions)
    @font-face {
        font-family: "Amiri";
        font-style: normal;
        src: url("#{$base_url}/Amiri-Regular.woff2") format("woff2"),
            url("#{$base_url}/Amiri-Regular.woff") format("woff");
        font-display: swap;
        unicode-range:
            // Arabic
            U+600-6FF,
            // Arabic Supplement
            U+750-77F,
            // Arabic Extended-A
            U+08A0-08FF,
            // Arabic Extended-B
            U+0870-089F,
            // Arabic Extended-C
            U+10EC0-10EFF,
            // Arabic Presentation Forms-A
            U+FB50-FDFF,
            // Arabic Presentation Forms-B
            U+FE70-FEFF,
            // Rumi Numeral Symbols
            U+10E60-10E7F,
            // Indic Siyaq Numbers
            U+1EC70-1ECBF,
            // Ottoman Siyaq Numbers
            U+1ED00-1ED4F,
            // Arabic Mathematical Alphabetic Symbols
            U+1EE00-1EEFF;
    }
}

// load fonts from local url at /static/fonts
@include declare-fonts("/static/fonts");

// Fallback fonts for ayin and hamza
@font-face {
    font-family: "arial-regular";
    src: local(Arial);
    size-adjust: 83%;
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
}

@font-face {
    font-family: "arial-bold";
    src: local(Arial);
    size-adjust: 83%;
    font-style: normal;
    font-weight: bold;
    font-display: fallback;
}

@font-face {
    font-family: "arial-italic";
    src: local(Arial);
    size-adjust: 83%;
    font-style: italic;
    font-weight: normal;
    font-display: fallback;
}

// Variables containing font stacks go here, for use as "font-family" values.
// We pick fallback fonts that are reasonably close to the desired webfont and
// also likely to be installed on the user's machine. For more, see:
// https://meowni.ca/font-style-matcher/
// https://www.cssfontstack.com/

// Primary font family: all Latin text, all Hebrew except transcriptions
$primary: (
    "Greta Sans Regular",
    "Greta Sans Hebrew Regular",
    "HassanLTLight",
    "arial-regular",
    sans-serif
);
$primary-bold: (
    "Greta Sans Regular Bold",
    "Greta Sans Hebrew Bold",
    "HassanLTBold",
    "arial-bold",
    sans-serif
);
$primary-semibold: (
    "Greta Sans Regular Semibold",
    "Greta Sans Hebrew Bold",
    "HassanLTBold",
    "arial-bold",
    sans-serif
);
$primary-italic: ("Greta Sans Regular Italic", "arial-italic", sans-serif);

// Transcriptions (Combined Hebrew and Arabic, chosen by Unicode character,
// with Times New Roman for missing glyphs)
$transcription: ("FrankRuhl 1924 MF Medium", "Amiri", "Times New Roman", serif);
