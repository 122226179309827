// -----------------------------------------------------------------------------
// Site footer that appears on every page.
// -----------------------------------------------------------------------------

@use "../base/breakpoints";
@use "../base/fonts";
@use "../base/spacing";
@use "../base/typography";

// Mixin used for icons to the left of text (span or link)
@mixin icon-and-text {
    a::before,
    span::before {
        @include typography.icon-button-md;
        margin: 0 0.2rem 0 0;
        vertical-align: middle;
    }
}
// RTL version of icon mixin
@mixin icon-and-text-rtl {
    a::after,
    span::after {
        @include typography.icon-button-md;
        margin: 0 0 0 0.2rem;
        vertical-align: middle;
    }
}

footer {
    * {
        max-width: 100%;
    }
    @include typography.meta;
    text-align: center;

    // footer desktop
    @include breakpoints.for-tablet-landscape-up {
        position: relative;
        margin-top: spacing.$spacing-2xl;
        padding: 3.2rem 4.75rem 0;
        display: flex;
        flex-flow: column;
        div.first-row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
    }
    // extra style for hiding the theme toggle on desktop only
    @include breakpoints.for-desktop-up {
        z-index: 4;
    }

    // footer site navigation (mobile)
    nav#footer-nav {
        margin: 26% 1rem 0; // Use percentages to scale with background
        @include breakpoints.for-tablet-landscape-up {
            margin: 0;
        }
        text-align: left;
        ul {
            display: grid;
            grid-template-rows: repeat(3, auto);
            grid-auto-flow: column dense;
            grid-auto-columns: 1fr 1fr;
            gap: 0 0.5rem;
            a {
                text-decoration: none;
                &[aria-current="page"] {
                    font-family: fonts.$primary-bold;
                    font-weight: bold;
                }
            }
            li {
                &.menu-label {
                    margin-bottom: spacing.$spacing-2xs;
                    text-transform: uppercase;
                    font-size: typography.$text-size-md;
                    font-family: fonts.$primary-bold;
                    font-weight: 600;
                    @include breakpoints.for-tablet-landscape-up {
                        font-size: typography.$text-size-sm;
                    }
                }
                &.menu-item {
                    min-height: 48px;
                    font-size: typography.$text-size-xl;
                    @include breakpoints.for-tablet-landscape-up {
                        font-size: typography.$text-size-md;
                        min-height: 0;
                    }
                }
                &.has-submenu {
                    grid-row-end: span 20; // Hack to get tight grid
                    ul {
                        display: flex;
                        flex-flow: column;
                    }
                }
            }
        }
    }

    // footer links to PGL, CDH
    svg {
        fill: var(--logo);
        &#pgl-logo {
            width: 177px;
            height: 85px;
        }
        &#cdh-logo {
            width: 108px;
            height: 80px;
        }
    }
    ul#footer-links {
        & > li {
            margin-top: spacing.$spacing-lg;
        }

        border-top: 1px solid var(--disabled);
        margin: 1.25rem 1.5rem;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.875rem;
        a.logo {
            display: flex;
            align-items: center;
            &::after {
                content: "";
                display: block;
                width: 1px;
                height: 4rem;
                background-color: var(--disabled);
                margin-left: 1.875rem;
            }
        }
        li:last-of-type a.logo::after {
            display: none;
        }
        // footer links (desktop)
        @include breakpoints.for-tablet-landscape-up {
            flex: 1 0 auto;
            justify-content: flex-end;
            border: none;
            margin: 0;
            gap: 0;
            & > li {
                margin: spacing.$spacing-md spacing.$spacing-lg 0 0;
            }
            a.logo {
                height: 90px;
                margin-bottom: spacing.$spacing-md;
                &::after {
                    margin-left: 2rem;
                }
            }
            li:last-of-type a.logo::after {
                display: block;
            }
        }
    }
    // social links
    section.social {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        ul {
            text-align: left;
        }
        @include breakpoints.for-tablet-landscape-up {
            text-align: left;
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            justify-content: center;
        }
        h2 {
            @include typography.body-bold;
            font-size: typography.$text-size-md;
            @include breakpoints.for-tablet-landscape-up {
                font-size: typography.$text-size-md;
            }
        }
        li {
            @include icon-and-text;
            line-height: 1.1;
            font-size: typography.$text-size-xl;
            min-height: 48px;
            @include breakpoints.for-tablet-landscape-up {
                font-size: typography.$text-size-sm;
                min-height: 0;
            }
            a.twitter::before {
                content: "\f341"; // phosphor twitter-logo icon
            }
            a.instagram::before {
                content: "\f1e1"; // phosphor instagram-logo icon
            }
        }
    }

    // Legal and accessibility matters list
    ul#legal {
        padding-top: spacing.$spacing-lg;
        margin: spacing.$spacing-md spacing.$spacing-xs;
        &:not(:first-child) {
            border-top: 1px solid var(--disabled);
        }
        @include breakpoints.for-tablet-landscape-up {
            font-size: typography.$text-size-md;
            height: 3.625rem;
            margin: 1rem 0 0;
            padding-top: 0;
            display: flex;
            align-items: center;
            gap: 1.5rem;
            li {
                // accessibility link li
                &:first-child {
                    text-align: left;
                }
                // Princeton logo li
                &:last-child {
                    text-align: right;
                }
            }
        }

        // accessibility assistance link
        a#accessibility {
            text-decoration: underline;
            text-decoration-thickness: 0.06rem;
            text-decoration-color: var(--on-background);
            text-decoration-skip-ink: none;
            text-underline-offset: 0.375rem;
        }

        // copyright text
        li#copyright {
            margin: 1rem 0 0;
            @include icon-and-text;
            span::before {
                content: "\f115"; // phosphor copyright icon
            }
            @include breakpoints.for-tablet-landscape-up {
                margin: 0;
                text-align: left;
            }
        }

        // CC-BY license icon, software version
        li#license,
        li#sw-version {
            margin: 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 2rem;
            }
            @include breakpoints.for-tablet-landscape-up {
                margin: 0;
            }
            a {
                text-decoration: none;
            }
        }

        li#license {
            min-height: 48px;
            @include breakpoints.for-tablet-landscape-up {
                min-height: 0;
                flex: 1 0 auto;
                justify-content: flex-start;
                a {
                    display: flex;
                    height: 1rem;
                }
            }
        }

        li#sw-version {
            // padding added for tap-target size
            a {
                padding-bottom: 1.5rem;
            }
            @include breakpoints.for-tablet-landscape-up {
                a {
                    padding-bottom: 0;
                }
                margin: 0;
                flex: 0 1 auto;
            }
        }

        // Princeton logo
        a#princeton-logo {
            display: block;
            height: 34px;
            svg {
                fill: var(--logo);
                width: 120px;
                height: 34px;
            }
        }
    }
}

// tweaks for places page (map)
#main:has(main.places) ~ footer {
    display: none; // Hide footer on mobile
    @include breakpoints.for-tablet-landscape-up {
        padding: 3.75rem 4.75rem 1.1rem;
        display: flex;
        margin: 0;
        ul#legal {
            a.logo {
                display: flex;
                align-items: center;
                svg {
                    height: 4.25rem;
                    &#pgl-logo {
                        width: 133px;
                    }
                }
                &::after {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 1.5rem;
                    background-color: var(--disabled);
                    margin-left: 1.875rem;
                }
            }
        }
    }
}

// tweaks for RTL footer for hebrew, arabic
html[dir="rtl"] footer {
    // reverse alignment and margins in footer nav
    nav#footer-nav {
        ul {
            li {
                text-align: right;
            }
        }
    }
    // reverse icons, alignment, and margin in external links
    ul#footer-links {
        a.logo {
            &::after {
                margin-left: 0;
                margin-right: 1.875rem;
            }
            @include breakpoints.for-tablet-landscape-up {
                justify-content: flex-start;
                &::after {
                    margin-left: 0;
                    margin-right: 2rem;
                }
            }
        }
        ul.social {
            li {
                @include icon-and-text-rtl;
                a.twitter::before,
                a.instagram::before {
                    content: none;
                }
                a.twitter::after {
                    content: "\f341"; // phosphor twitter-logo icon
                }
                a.instagram::after {
                    content: "\f1e1"; // phosphor instagram-logo icon
                }
                @include breakpoints.for-tablet-landscape-up {
                    text-align: left;
                }
            }
        }
        @include breakpoints.for-tablet-landscape-up {
            justify-content: flex-end;
            & > li {
                margin: spacing.$spacing-md 0 0 spacing.$spacing-lg;
            }
        }
    }
    // reverse icon, padding, margin, and alignment in legal section
    ul#legal {
        li#copyright {
            @include icon-and-text-rtl;
            span::before {
                content: none;
            }
            span::after {
                content: "\f115"; // phosphor copyright icon
            }
        }
        @include breakpoints.for-tablet-landscape-up {
            li {
                &:first-child {
                    text-align: right;
                }
                &:last-child {
                    text-align: left;
                }
            }
        }
    }
}

html[dir="rtl"] #main:has(main.places) ~ footer {
    @include breakpoints.for-tablet-landscape-up {
        ul#legal a.logo::after {
            margin-left: 0;
            margin-right: 1.875rem;
        }
    }
}

// Footer backgrounds for light and dark mode, mobile
@mixin footer-background-mobile-dark {
    background: url("/static/img/ui/mobile/dark/all/footer-gradient.svg") top,
        url("/static/img/ui/mobile/dark/all/footer-image.png") top,
        url("/static/img/ui/mobile/dark/all/footer-base.svg") top;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 100% auto, 100% auto, 100% auto;
}
@mixin footer-background-mobile-light {
    background: url("/static/img/ui/mobile/light/all/footer-gradient.svg") top,
        url("/static/img/ui/mobile/light/all/footer-image.png") top,
        url("/static/img/ui/mobile/light/all/footer-base.svg") top;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 100% auto, 100% auto, 100% auto;
}

// Footer background extension for large screens
@mixin before-footer-desktop {
    position: absolute;
    content: " ";
    height: 100%;
    display: block;
    left: 0;
    z-index: -1;
}

// Footer backgrounds for light and dark mode, desktop
@mixin footer-background-desktop-dark {
    background: url("/static/img/ui/desktop/dark/ltr/footer-image.png") top
        right no-repeat;
    &::before {
        @include before-footer-desktop;
        background: url("/static/img/ui/desktop/dark/all/footer-filler.png")
            repeat-x;
        right: 1728px;
        bottom: 0;
    }
}
@mixin footer-background-desktop-sm-dark {
    background: url("/static/img/ui/desktop/dark/ltr/footer-image-sm.png") top
        right no-repeat;
    &::before {
        @include before-footer-desktop;
        background: url("/static/img/ui/desktop/dark/all/footer-filler-sm.png")
            repeat-x;
        right: 1728px;
        bottom: 0;
    }
}
@mixin footer-background-desktop-dark-rtl {
    background: url("/static/img/ui/desktop/dark/rtl/footer-image.png") top left
        no-repeat;
    &::before {
        @include before-footer-desktop;
        background: url("/static/img/ui/desktop/dark/all/footer-filler.png")
            repeat-x;
        right: 0;
        left: 1728px;
        bottom: 0;
    }
}
@mixin footer-background-desktop-sm-dark-rtl {
    background: url("/static/img/ui/desktop/dark/rtl/footer-image-sm.png") top
        left no-repeat;
    &::before {
        @include before-footer-desktop;
        background: url("/static/img/ui/desktop/dark/all/footer-filler-sm.png")
            repeat-x;
        right: 0;
        left: 1728px;
        bottom: 0;
    }
}
@mixin footer-background-desktop-light {
    background: url("/static/img/ui/desktop/light/ltr/footer-image.png") top
        right no-repeat;
    &::before {
        @include before-footer-desktop;
        background: url("/static/img/ui/desktop/light/all/footer-filler.png")
            repeat-x;
        right: 1728px;
        bottom: 0;
    }
}
@mixin footer-background-desktop-sm-light {
    background: url("/static/img/ui/desktop/light/ltr/footer-image-sm.png") top
        right no-repeat;
    &::before {
        @include before-footer-desktop;
        background: url("/static/img/ui/desktop/light/all/footer-filler-sm.png")
            repeat-x;
        right: 1728px;
        bottom: 0;
    }
}
@mixin footer-background-desktop-light-rtl {
    background: url("/static/img/ui/desktop/light/rtl/footer-image.png") top
        left no-repeat;
    &::before {
        @include before-footer-desktop;
        background: url("/static/img/ui/desktop/light/all/footer-filler.png")
            repeat-x;
        right: 0;
        left: 1728px;
        bottom: 0;
    }
}
@mixin footer-background-desktop-sm-light-rtl {
    background: url("/static/img/ui/desktop/light/rtl/footer-image-sm.png") top
        left no-repeat;
    &::before {
        @include before-footer-desktop;
        background: url("/static/img/ui/desktop/light/all/footer-filler-sm.png")
            repeat-x;
        right: 0;
        left: 1728px;
        bottom: 0;
    }
}
// Nav theme overrides for light mode
@mixin light-footer {
    @include footer-background-mobile-light;
    @include breakpoints.for-tablet-landscape-up {
        @include footer-background-desktop-light;
    }
}
@mixin light-footer-rtl {
    @include footer-background-mobile-light;
    @include breakpoints.for-tablet-landscape-up {
        @include footer-background-desktop-light-rtl;
    }
}
@mixin light-footer-sm {
    @include breakpoints.for-tablet-landscape-up {
        @include footer-background-desktop-sm-light;
    }
}
@mixin light-footer-sm-rtl {
    @include breakpoints.for-tablet-landscape-up {
        @include footer-background-desktop-sm-light-rtl;
    }
}
// Nav theme overrides for dark mode
@mixin dark-footer {
    @include footer-background-mobile-dark;
    @include breakpoints.for-tablet-landscape-up {
        @include footer-background-desktop-dark;
    }
}
@mixin dark-footer-rtl {
    @include footer-background-mobile-dark;
    @include breakpoints.for-tablet-landscape-up {
        @include footer-background-desktop-dark-rtl;
    }
}
@mixin dark-footer-sm {
    @include breakpoints.for-tablet-landscape-up {
        @include footer-background-desktop-sm-dark;
    }
}
@mixin dark-footer-sm-rtl {
    @include breakpoints.for-tablet-landscape-up {
        @include footer-background-desktop-sm-dark-rtl;
    }
}
// Applying background image and gradient per system theme
footer {
    @include light-footer;
    @media (prefers-color-scheme: dark) {
        @include dark-footer;
    }
}
#main:has(main.places) ~ footer {
    @include light-footer-sm;
    @media (prefers-color-scheme: dark) {
        @include dark-footer-sm;
    }
}
html[dir="rtl"] footer {
    @include light-footer-rtl;
    @media (prefers-color-scheme: dark) {
        @include dark-footer-rtl;
    }
}
html[dir="rtl"] #main:has(main.places) ~ footer {
    @include light-footer-sm-rtl;
    @media (prefers-color-scheme: dark) {
        @include dark-footer-sm-rtl;
    }
}

// Manual (body-class based) theme overrides
html.light-mode footer {
    @include light-footer;
}
html.dark-mode footer {
    @include dark-footer;
}
html.light-mode #main:has(main.places) ~ footer {
    @include light-footer-sm;
}
html.dark-mode #main:has(main.places) ~ footer {
    @include dark-footer-sm;
}

html[dir="rtl"].light-mode footer {
    @include light-footer-rtl;
}
html[dir="rtl"].dark-mode footer {
    @include dark-footer-rtl;
}
html[dir="rtl"].light-mode #main:has(main.places) ~ footer {
    @include light-footer-sm-rtl;
}
html[dir="rtl"].dark-mode #main:has(main.places) ~ footer {
    @include dark-footer-sm-rtl;
}

// Hebrew variant
html[lang="he"] footer {
    @include typography.meta-he;
    *[lang="en"] {
        // handle any nav links in English
        @include typography.meta;
    }
}

// Arabic variant
html[lang="ar"] footer {
    @include typography.meta-ar;
    *[lang="en"] {
        // handle any nav links in English
        @include typography.meta;
    }
}
