/* styles for image + transcription viewer */
@use "../base/a11y";
@use "../base/breakpoints";
@use "../base/colors";
@use "../base/fonts";
@use "../base/spacing";
@use "../base/typography";
@use "../components/searchform";

/*
 * Mixin to add a box-shadow given a color.
 * @param {color} $color - The color variable
*/
@mixin box-shadow($color) {
    box-shadow: 0px 2px 4px $color;
}

/*
 * Mixin for form drop shadow in three states:
 * default, focus, active.
 */
@mixin form-drop-shadow($with-default: "true") {
    transition: box-shadow 0.15s ease;
    @include box-shadow(var(--on-background-25));
    &:focus,
    &:focus-within {
        outline: none;
        @include box-shadow(var(--secondary-80));
    }
    &:active {
        @include box-shadow(var(--primary-80));
    }
}

#itt-panel {
    * {
        max-width: 100vw;
    }
    margin-top: 5.125rem;
    max-width: 100vw;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    background-color: transparent;

    #toggles {
        // toggle switches for panels
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        gap: 1rem;
        width: 100%;
        align-items: center;
        margin-bottom: 2rem;
        @include breakpoints.for-tablet-landscape-up {
            margin-bottom: 4rem;
        }

        input[type="checkbox"].toggle {
            // disable browser checkbox UI
            @include a11y.sr-only;

            // style labels instead
            & + label {
                background-color: var(--background-gray);
                color: var(--on-background);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                margin: 0;
                height: 80px;
                width: 80px;
                border-radius: 5px;
                @include breakpoints.for-tablet-landscape-up {
                    transition: background-color 300ms ease, color 300ms ease;
                    cursor: pointer;
                    &:active {
                        background-color: var(--disabled);
                    }
                }
            }

            @include breakpoints.for-tablet-landscape-up {
                &:checked + label {
                    background-color: var(--secondary);
                    color: var(--on-secondary);
                    &:active {
                        background-color: var(--filter-active);
                    }
                }
                &:focus-visible + label {
                    border: 2px solid var(--button-focus);
                }
                &:checked:focus-visible + label {
                    border: 2px solid var(--button-focus);
                }
            }

            // checkmark icons (mobile only)
            & + label:after {
                content: "\f0e8"; // phosphor check-circle icon
            }
            &:checked + label:after {
                content: "\f851"; // phosphor check-circle-fill icon
            }
            & + label:after,
            &:checked + label:after {
                display: block;
                color: var(--secondary);
                @include typography.icon-button-md;
                // disable on desktop
                @include breakpoints.for-tablet-landscape-up {
                    display: none;
                }
            }

            // disabled toggle colors
            &:disabled + label {
                background-color: var(--background-light);
                cursor: default;
                &:after,
                &:before,
                svg {
                    color: var(--disabled);
                }
            }
        }
    }

    // toggle icons
    input#transcription-on + label svg,
    div.transcription-header svg {
        width: 15px;
        height: 20px;
    }
    input#translation-on + label svg,
    div.translation-header svg {
        width: 12px;
        height: 16px;
    }
    input#images-on + label:before,
    div.img-header span:before {
        @include typography.icon-button-md;
        content: "\f1dd"; // phosphor ph-image icon
        font-weight: normal;
    }

    // multiple transcription pseudo-select element
    details.itt-select {
        position: relative;
        width: 100%;
        height: 100%;
        summary {
            height: 100%;
            display: flex;
            flex-flow: row;
            cursor: pointer;
            // Suppress default details marker
            &::-webkit-details-marker {
                display: none;
            }
            // label for current selection
            span {
                cursor: pointer;
                width: 1%;
                box-sizing: border-box;
                color: var(--on-background);
                flex: 1 0 auto;
                display: flex;
                justify-content: flex-start;
                gap: 0.5rem;
                align-items: center;
                @include typography.meta-bold;
                @include breakpoints.for-tablet-landscape-up {
                    gap: 0.75rem;
                }
                &::after {
                    content: "\f0c2"; // phosphor caret-down icon
                    @include typography.icon-button-md;
                    float: right;
                    flex-grow: 1;
                    text-align: right;
                    margin-right: 1rem;
                    @include breakpoints.for-tablet-landscape-up {
                        margin-right: 0;
                    }
                }
                &.escr:before {
                    content: " ";
                    display: inline-block;
                    width: 1.5rem;
                    height: 1.5rem;
                    background-color: var(--secondary);
                    mask-image: url("/static/img/logos/all/all/escr-logo.svg");
                    mask-repeat: no-repeat;
                    @include breakpoints.for-tablet-landscape-up {
                        width: 2.25rem;
                        height: 2.25rem;
                    }
                }
            }
        }
        // List of transcription options
        ul {
            position: absolute;
            top: 3.5rem;
            left: 0.333rem;
            z-index: 20;
            width: calc(100% - 0.667rem);
            padding: spacing.$spacing-sm 0;
            @include form-drop-shadow;
            background-color: var(--background);
            // label for single transcription option
            li {
                display: flex;
                label {
                    width: 100%;
                    flex: 0 1 100%;
                    display: flex;
                    cursor: pointer;
                    span {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        flex: 0 1 100%;
                        padding: spacing.$spacing-sm;
                        transition: background-color 0.15s ease,
                            color 0.15s ease;
                    }
                    &:hover,
                    &:focus-within {
                        background-color: var(--background-gray);
                        color: var(--on-background-gray);
                    }
                    &:hover input[name="transcription"]:focus + span,
                    &:hover input[name="translation"]:focus + span {
                        background-color: var(--tertiary);
                        color: var(--on-tertiary);
                    }
                    // Checkboxes only visible to screen readers
                    input[name="transcription"],
                    input[name="translation"] {
                        @include a11y.sr-only;
                        // Selected option
                        &:checked + span {
                            @include typography.form-option-sm-bold;
                        }
                        &:active + span {
                            background-color: var(--secondary);
                            color: var(--on-secondary);
                        }
                        &:disabled + span {
                            color: var(--disabled);
                            cursor: default;
                            background-color: var(--background-light);
                        }
                    }
                    // Non-selected options
                    @include typography.form-option-sm;
                }
            }
        }
        // disable interaction when only 1 transcription (cannot use :disabled on <details>)
        &[disabled="true"] {
            * {
                cursor: default;
            }
            summary span::after,
            ul {
                display: none;
            }
        }
        // spacing tweaks for transcription selector on desktop
        @include breakpoints.for-tablet-landscape-up {
            width: auto;
            flex: 1 0 40vw;
            min-height: 2.5rem;
            // label for current selection
            summary {
                min-height: 2.5rem;
                span {
                    padding: 20px;
                    display: flex;
                }
            }
        }
    }

    #header-row {
        z-index: 6;
        display: none;
        width: 100%;
        gap: 0.5rem;
        div {
            height: 60px;
            background-color: var(--secondary);
            color: var(--on-secondary);
            span {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 30px;
                line-height: 1;
                margin: 0;
                @include typography.body-bold;
            }
        }
        div,
        div.img-header span {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.75rem;
            flex: 1 0 auto;
        }
        div {
            display: none;
        }
    }

    @include breakpoints.for-tablet-landscape-up {
        #toggles:has(*:checked) ~ #header-row {
            display: grid;
        }

        #toggles:has(#images-on:checked) ~ #header-row div.img-header,
        #toggles:has(#transcription-on:checked)
            ~ #header-row
            div.transcription-header,
        #toggles:has(#translation-on:checked)
            ~ #header-row
            div.translation-header {
            display: flex;
        }
    }

    #toggles:has(#images-on:checked) ~ #header-row,
    #toggles:has(#images-on:checked) ~ .panel-container,
    #toggles:has(#transcription-on:checked) ~ #header-row,
    #toggles:has(#transcription-on:checked) ~ .panel-container,
    #toggles:has(#translation-on:checked) ~ #header-row,
    #toggles:has(#translation-on:checked) ~ .panel-container {
        /* one enabled: always 1 column */
        grid-template-columns: 100%;
    }
    #toggles:has(#images-on:checked ~ #transcription-on:checked) ~ #header-row,
    #toggles:has(#images-on:checked ~ #transcription-on:checked)
        ~ .panel-container,
    #toggles:has(#images-on:checked ~ #translation-on:checked) ~ #header-row,
    #toggles:has(#images-on:checked ~ #translation-on:checked)
        ~ .panel-container,
    #toggles:has(#transcription-on:checked ~ #translation-on:checked)
        ~ #header-row,
    #toggles:has(#transcription-on:checked ~ #translation-on:checked)
        ~ .panel-container {
        /* for mobile, set to full-width; content will stack in order */
        grid-template-columns: 100%;
        @include breakpoints.for-tablet-landscape-up {
            /* desktop: two column layout */
            grid-template-columns: 50% 50%;
        }
    }
    #toggles:has(#images-on:checked
            ~ #transcription-on:checked
            ~ #translation-on:checked)
        ~ #header-row,
    #toggles:has(#images-on:checked
            ~ #transcription-on:checked
            ~ #translation-on:checked)
        ~ .panel-container {
        /* for mobile, set to full-width; content will stack in order */
        grid-template-columns: 100%;
        @include breakpoints.for-tablet-landscape-up {
            /* desktop: three column layout */
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    .panel-container {
        display: grid;
        z-index: 6;
        gap: 0 0.5rem;
        width: 100%;
        /* when no content panels are selected, don't show anything  */
        height: 0;
        overflow: hidden;

        /* hide panel containers by default */
        & > div {
            height: 0; /* ensure not visible when not selected */
            overflow: hidden;
            max-width: 100%;
            background-color: var(--background-light);
        }
        /* hide enlarge button on mobile */
        button.enlarge-button {
            display: none;
        }
        @include breakpoints.for-tablet-landscape-up {
            padding: 0;
            display: grid;
        }
    }

    /* if any of the inputs are checked, panel container will be expanded */
    #toggles:has(*:checked) ~ .panel-container {
        height: auto;
    }

    /* images only */
    #toggles:has(#images-on:checked) ~ .panel-container {
        /* image column full width; transcription, translation columns collapsed */
        padding-bottom: 30px;

        & > div:first-of-type {
            height: 100%;
        }

        // image panel spacing
        div.img {
            margin: 0 auto;
            /* enlarge button and enlarge functionality */
            @include breakpoints.for-tablet-landscape-up {
                transition: max-width 0.3s ease-in-out;
                & > div {
                    max-width: 640px;
                    margin: 0 auto;
                    transition: max-width 0.3s ease-in-out;
                }
                img {
                    transition: width 0.3s ease-in-out;
                }
                &.enlarged {
                    & > div {
                        max-width: 100%;
                    }
                    img {
                        width: 100%;
                    }
                }
                button.enlarge-button {
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    border: none;
                    background: transparent;
                    padding: 0;
                    margin: 0 0 1.2rem;
                    z-index: 2;
                    &::after {
                        color: var(--on-background);
                        content: "\f118";
                        margin-left: 1rem;
                        @include typography.icon-button-md;
                    }
                    &:active::after {
                        color: var(--primary);
                    }
                    &.active::after {
                        content: "\f117";
                    }
                }
            }
        }

        // Attribution and license text
        details.permissions {
            display: block;
            margin: 0;
            &[open] summary {
                justify-content: center;
            }
            @include breakpoints.for-tablet-landscape-up {
                &[open] summary {
                    justify-content: flex-start;
                }
            }
        }
    }

    /* common styles between the above two */
    #toggles:has(#translation-on:checked) ~ .panel-container,
    #toggles:has(#transcription-on:checked) ~ .panel-container {
        padding-bottom: 30px;
        // Attribution and license text
        details.permissions {
            display: none;
        }
        background-color: var(--background-light);
        // transcription panel spacing
        div.transcription-panel,
        div.translation-panel {
            width: 100%;
            max-width: 640px;
            margin: auto;
            &.label-row {
                padding-left: 0;
                width: 100%;
                max-width: 640px;
                overflow: visible;
            }
        }
    }

    #toggles:not(:has(#images-on:checked)) ~ .panel-container .img,
    #toggles:not(:has(#images-on:checked))
        ~ .panel-container
        > div:first-of-type,
    #toggles:not(:has(#transcription-on:checked))
        ~ .panel-container
        .transcription-panel,
    #toggles:not(:has(#translation-on:checked))
        ~ .panel-container
        .translation-panel {
        display: none;
    }

    /* both image & transcription checked */
    #toggles:has(#images-on:checked ~ #transcription-on:checked)
        ~ .panel-container {
        justify-items: stretch;
        @include breakpoints.for-tablet-landscape-up {
            div.translation-panel > div,
            div.transcription-panel > div,
            div.translation-panel > span,
            div.transcription-panel > span {
                max-width: none;
                margin: 0;
            }
        }

        // transcription panel spacing
        div.transcription-panel {
            width: 100%;
            max-width: none;
        }
    }

    /* both image & translation checked */
    #toggles:has(#images-on:checked ~ #translation-on:checked)
        ~ .panel-container {
        justify-items: stretch;
        @include breakpoints.for-tablet-landscape-up {
            div.translation-panel > div,
            div.transcription-panel > div,
            div.translation-panel > span,
            div.transcription-panel > span {
                max-width: none;
                margin: 0;
            }
        }

        // translation panel spacing
        div.translation-panel {
            width: 100%;
            max-width: none;
        }
    }

    /* common styles between the above two */
    #toggles:has(#images-on:checked ~ #transcription-on:checked)
        ~ .panel-container,
    #toggles:has(#images-on:checked ~ #translation-on:checked)
        ~ .panel-container {
        // image panel spacing
        div.img {
            margin: 0 auto;
        }
        // Attribution and license text
        details.permissions {
            display: block;
            margin: 30px auto 0;
            &[open] summary {
                justify-content: center;
            }
            @include breakpoints.for-tablet-landscape-up {
                margin: 0;
                &[open] summary {
                    justify-content: flex-start;
                }
            }
        }
        // image enlarge button
        button.enlarge-button {
            display: none;
        }
        @include breakpoints.for-tablet-landscape-up {
            background-color: transparent;
            & > div.transcription-panel,
            & > div.translation-panel {
                max-width: none;
            }
        }
    }

    /* both transcription & translation checked */
    #toggles:has(#transcription-on:checked ~ #translation-on:checked)
        ~ .panel-container {
        /* for mobile, set to full-width; content will stack in order */
        justify-items: stretch;
        @include breakpoints.for-tablet-landscape-up {
            div.translation-panel.label-row {
                margin: auto;
            }
            div.translation-panel > div,
            div.transcription-panel > div,
            div.translation-panel > span,
            div.transcription-panel > span {
                max-width: none;
                margin: 0;
            }
            // adjust line heights to align transcription with translation when both visible
            // give li a standard line height (using largest among transcription/translation styles)
            li {
                line-height: calc(34 / 22 * 1.375rem);
            }
            // remove margin/padding that could interrupt alignment
            li p {
                margin: 0;
                padding: 0;
            }
            li[data-lines="1"] {
                min-height: calc(1 * 34 / 22 * 1.375rem);
                max-height: calc(1 * 34 / 22 * 1.375rem);
            }
            li[data-lines="2"] {
                // li, height of two lines (line height = 34 / 22, font size = 1.375rem)
                min-height: calc(2 * 34 / 22 * 1.375rem);
                max-height: calc(2 * 34 / 22 * 1.375rem);
            }
            li[data-lines="3"] {
                min-height: calc(3 * 34 / 22 * 1.375rem);
                max-height: calc(3 * 34 / 22 * 1.375rem);
            }
            li[data-lines="4"] {
                min-height: calc(4 * 34 / 22 * 1.375rem);
                max-height: calc(4 * 34 / 22 * 1.375rem);
            }
            li[data-lines="5"] {
                min-height: calc(5 * 34 / 22 * 1.375rem);
                max-height: calc(5 * 34 / 22 * 1.375rem);
            }
            li[data-lines="6"] {
                min-height: calc(6 * 34 / 22 * 1.375rem);
                max-height: calc(6 * 34 / 22 * 1.375rem);
            }
            span.current-transcription,
            span.current-translation {
                // citation, height of two lines (line height = 1.5, font size = 1.125rem)
                min-height: calc(2 * 1.5 * 1.125rem);
            }
            background-color: transparent;
            & > div.transcription-panel,
            & > div.translation-panel {
                max-width: none;
            }
        }
    }

    /* all three image, transcription & translation checked */
    #toggles:has(#images-on:checked
            ~ #transcription-on:checked
            ~ #translation-on:checked)
        ~ .panel-container {
        @include breakpoints.for-tablet-landscape-up {
            padding: 0;
            gap: 0 0.5rem;
            div.img .img-header {
                gap: 0 0.5rem;
                width: 100%;
                h2 {
                    flex: 1 1 auto;
                }
                input[name="zoom-slider-toggle"] + label,
                input[name="rotation-slider-toggle"] + label {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 42px;
                    max-width: 42px;
                    height: 32px;
                    border-radius: 5px;
                    margin-bottom: 1.2rem;
                    color: var(--on-background);
                    background-color: var(--background-gray);
                    font-size: typography.$text-size-xl;
                }
                input[name="zoom-slider-toggle"]:checked + label,
                input[name="rotation-slider-toggle"]:checked + label {
                    color: var(--on-secondary);
                    background-color: var(--secondary);
                }
                .zoom-control,
                .rotation-control {
                    display: none;
                }
                input[name="zoom-slider-toggle"]:checked
                    ~ div.img-controls
                    .zoom-control {
                    display: flex;
                }
                input[name="rotation-slider-toggle"]:checked
                    ~ div.img-controls
                    .rotation-control {
                    display: flex;
                }
                input[name="zoom-slider-toggle"]:not(:checked)
                    ~ input[name="rotation-slider-toggle"]:not(:checked)
                    ~ div.img-controls {
                    display: none;
                }
                .edit-mode-controls {
                    margin-bottom: 1.2rem;
                    justify-content: flex-start;
                }
            }
        }
        @include breakpoints.for-desktop-up {
            div.img .img-header {
                h2 {
                    padding-bottom: 0.5rem;
                }
                input[name="zoom-slider-toggle"] + label,
                input[name="rotation-slider-toggle"] + label {
                    display: none;
                }
                input[name="zoom-slider-toggle"]:not(:checked)
                    ~ input[name="rotation-slider-toggle"]:not(:checked)
                    ~ div.img-controls {
                    display: flex;
                }
                .zoom-control {
                    display: flex;
                }
                .rotation-control {
                    display: flex;
                }
                .edit-mode-controls {
                    margin-bottom: 0;
                    justify-content: flex-end;
                }
            }
        }
    }

    /* content panels should have normal height when enabled */
    #toggles:has(#images-on:checked) ~ .panel-container .img,
    #toggles:has(#transcription-on:checked)
        ~ .panel-container
        .transcription-panel,
    #toggles:has(#translation-on:checked)
        ~ .panel-container
        .translation-panel {
        height: 100%;
    }

    /* image panel styles */
    .panel-container div.img {
        text-align: left;
        width: 100%;
        overflow: visible;
        * {
            max-width: none;
        }

        &.placeholder {
            // hide placeholder zoom controls
            .img-header *:not(h2) {
                display: none !important;
            }
            // show placeholder label
            .img-header h2 {
                display: block;
            }
            // placeholders are completely transparent in viewer
            .deep-zoom-container {
                opacity: 0;
            }
        }
        @include breakpoints.for-tablet-landscape-up {
            max-width: none;
            padding: 0 20px;
        }
        .img-header {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin-bottom: 8px;
            @include breakpoints.for-tablet-landscape-up {
                flex-flow: row wrap;
                margin-bottom: 0;
                justify-content: space-between;
            }
            h2 {
                @include typography.meta-bold;
                text-align: left;
                margin: 8px;
                display: inline-block;
                flex: 1 1 auto;
                @include breakpoints.for-tablet-landscape-up {
                    flex: 1 0 calc(100% - 7.25rem - 0.5rem);
                    display: flex;
                    align-items: center;
                    padding: 0.8rem 0;
                    margin: 0;
                }
                @media (min-width: 1360px) {
                    flex: 1 0 100%;
                }
            }
            // collect image controls in a div
            div.img-controls {
                display: flex;
                max-width: 100%;
                flex-flow: row;
                align-items: center;
                justify-content: flex-end;
                flex: 1 1 auto;
                gap: 1rem;
            }
            input[name="zoom-slider-toggle"],
            input[name="zoom-slider-toggle"] + label,
            input[name="rotation-slider-toggle"],
            input[name="rotation-slider-toggle"] + label {
                display: none;
                & + label {
                    display: none;
                }
            }
            // editor controls
            .edit-mode-controls {
                display: none;
                @include breakpoints.for-tablet-landscape-up {
                    display: flex;
                }
                flex-flow: row nowrap;
                justify-content: flex-end;
                flex: 1 1 7.25rem;
                @media (min-width: 1360px) {
                    margin-bottom: 1.2rem;
                    justify-content: flex-start;
                }
            }
            // zoom control (desktop)
            .zoom-control,
            .rotation-control {
                flex: 1 1 auto;
                width: calc(50% - 1rem);
                display: none;
                @include breakpoints.for-tablet-landscape-up {
                    display: flex;
                }
                gap: 0.25rem;
                flex-flow: row nowrap;
                align-items: center;
                background-color: var(--background-gray);
                padding: 0 0.875rem;
                height: 2rem;
                border-radius: 5px;
                margin-bottom: 1.2rem;
                &::before {
                    font-family: "Phosphor" !important;
                    font-size: 1.25rem;
                    margin-right: 0.5rem;
                }
            }
            .zoom-control::before {
                content: "\f20a"; // phosphor magnifying glass icon
            }
            .rotation-control::before {
                content: "\f045"; // phosphor arrows clockwise icon
            }
            input[type="range"] {
                display: none;
                @include breakpoints.for-tablet-landscape-up {
                    display: block;
                }
                flex: 1 1 auto;
                -webkit-appearance: none;
                appearance: none;
                background: var(--zoom-control-bg);
                height: 4px;
                border-radius: 4px;
                cursor: pointer;

                // range slider control circle/"thumb"
                // sadly, these are still vendor-prefixed and can't be combined to reduce redunancy :(
                &::-webkit-slider-thumb {
                    -webkit-appearance: none; /* Override Webkit default appearance */
                    appearance: none;
                    background-color: var(--filter-active);
                    transition: background-color 300ms ease;
                    border-radius: 20px;
                    height: 12px;
                    width: 12px;
                }
                &::-moz-range-thumb {
                    background-color: var(--filter-active);
                    transition: background-color 300ms ease;
                    border-radius: 20px;
                    height: 12px;
                    width: 12px;
                    border: none;
                }
                &.active-thumb {
                    &::-webkit-slider-thumb {
                        background-color: var(--link-primary);
                    }
                    &::-moz-range-thumb {
                        background-color: var(--link-primary);
                    }
                }
            }
            .zoom-control input[type="range"] {
                max-width: calc(100% - 1rem - 60px);
            }
            .rotation-control input[type="range"] {
                max-width: calc(100% - 1rem - 50px);
            }
            label[for^="zoom-slider"] {
                min-width: 40px;
                max-width: 40px;
            }
            label[for^="rotation"] {
                min-width: 30px;
                max-width: 30px;
            }
            label[for^="zoom-slider"],
            label[for^="rotation"] {
                display: none;
                @include breakpoints.for-tablet-landscape-up {
                    display: block;
                }
                text-align: right;
                flex: 0 1 40px;
                color: var(--on-background);
                font-family: fonts.$primary;
                font-size: typography.$text-size-sm;
            }
            label[for^="rotation"] {
                flex: 0 1 30px;
            }
            // zoom control (mobile)
            input[type="checkbox"][id^="zoom-toggle"] {
                display: block;
                @include a11y.sr-only;
                @include breakpoints.for-tablet-landscape-up {
                    display: none;
                }
                & + label[for^="zoom-toggle"] {
                    flex: 1 0 auto;
                    display: flex;
                    @include breakpoints.for-tablet-landscape-up {
                        display: none;
                    }
                    align-items: center;
                    justify-content: flex-end;
                    text-align: right;
                    padding-right: 8px;
                    color: var(--on-background);
                    @include typography.meta;
                    &:after {
                        content: " ";
                        display: block;
                        height: 41px;
                        width: 66px;
                        margin-left: 8px;
                        background-image: url("/static/img/ui/mobile/all/deep-zoom-toggle-off.svg");
                    }
                }
                &:checked + label[for^="zoom-toggle"]:after {
                    background-image: url("/static/img/ui/mobile/light/all/deep-zoom-toggle-on.svg");
                    @media (prefers-color-scheme: dark) {
                        background-image: url("/static/img/ui/mobile/dark/all/deep-zoom-toggle-on.svg");
                    }
                }
            }
        }
        .deep-zoom-container {
            position: relative;
            width: 100%;
            text-align: center;
            .osd {
                // height of screen, minus height of (controls + padding)
                height: min(calc(100vh - 99px), 100%);
                @include breakpoints.for-tablet-landscape-up {
                    // controls are vertically slimmer on desktop
                    height: min(calc(100vh - 68px), 100%);
                }
                position: absolute !important;
                top: 0;
            }
            img,
            .osd {
                width: 100%;
                &.hidden-img {
                    transition: opacity 300ms ease, visibility 0s ease 300ms;
                    opacity: 0;
                    visibility: hidden;
                }
                &.visible {
                    transition: opacity 300ms ease, visibility 0s ease 0ms;
                    visibility: visible;
                    opacity: 1;
                }
            }
            img {
                @include breakpoints.for-tablet-landscape-up {
                    width: 640px;
                    max-width: 100%; /* say within grid cell */
                }
            }
        }
        // images that are not included in the document
        &.excluded {
            .img-header {
                margin-left: auto;
                margin-right: auto;
                width: 198px;
                @include breakpoints.for-tablet-landscape-up {
                    width: 400px;
                }
            }
            .deep-zoom-container {
                margin-left: auto;
                margin-right: auto;
                max-width: 198px;
                max-height: 268px;
                img {
                    max-width: 198px;
                    max-height: 268px;
                }
                @include breakpoints.for-tablet-landscape-up {
                    max-width: 400px;
                    max-height: 540px;
                    img {
                        max-width: 400px;
                        max-height: 540px;
                    }
                }
                // 60% white overlay over image
                &::after {
                    position: absolute;
                    content: "";
                    display: block;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(255, 255, 255, 0.6);
                }
                // no need for osd div
                .osd {
                    display: none;
                }
            }
        }
        // related documents, to appear by excluded image(s)
        .excluded-related {
            display: flex;
            flex-flow: column;
            align-items: flex-end;
            width: 198px;
            margin: 31px auto;
            @include breakpoints.for-tablet-landscape-up {
                width: 400px;
                margin: 33px auto;
            }
            &:empty {
                display: none;
            }
            a.view-link {
                align-items: center;
                margin-top: 0;
                color: var(--link-primary);
                border-bottom: 3px solid var(--background-light);
                @include typography.body;
                // span inside span = related document title
                & > span {
                    @include typography.meta-bold;
                    color: var(--link-primary);
                    border-bottom: none;
                    /* space between text and document label */
                    margin-left: 0.25em;
                    /* space before shelfmark after type */
                    & > span {
                        margin-left: 0.25em;
                    }
                }
                &:hover {
                    border-bottom-color: var(--icon-button);
                    @include breakpoints.for-tablet-landscape-up {
                        border-bottom-color: var(--icon-button-hover);
                    }
                }
                &:active {
                    border-bottom-color: var(--icon-button);
                }
            }
        }
        // top spacing for included after excluded image panel
        &.excluded + div.transcription-panel + div.img,
        &.excluded
            + div.transcription-panel
            + div.img
            + div.transcription-panel {
            padding-top: 30px;
            @include breakpoints.for-tablet-landscape-up {
                padding-top: 130px;
            }
        }
        // top spacing for excluded after included image panel
        &:not(.excluded)
            + div.transcription-panel:not(.label-row)
            + div.img.excluded {
            padding-top: 30px;
            @include breakpoints.for-tablet-landscape-up {
                padding-top: 130px;
            }
        }
    }
    #toggles:has(#transcription-on:checked)
        ~ .panel-container
        div.img
        .deep-zoom-container
        img,
    #toggles:has(#translation-on:checked)
        ~ .panel-container
        div.img
        .deep-zoom-container
        img {
        width: 100%;
        transition: none;
    }
    // transcription/translation panel styles
    .panel-container div.transcription-panel,
    .panel-container div.translation-panel {
        width: 100%;
        * {
            max-width: none;
        }
        padding: 20px 10px 0;
        // "Transcription"/"Translation" header
        & > h2 {
            margin: 30px 0 12px;
        }
        // edition indicator/switcher
        &.label-row {
            padding-right: 0;
            padding-top: 0;
            margin-top: 1px; /* show a line between space and dropdown */
            max-width: none;
            span {
                width: 100%;
                margin: 0.5rem;
                @include breakpoints.for-tablet-landscape-up {
                    margin: 0;
                }
            }
        }

        // "carousel" for switching between multiple transcriptions/translations
        div.editions,
        div.translations {
            display: flex;
            overflow-x: hidden;
            overflow-y: clip; // prevent overflow-y scrollbar
            // NOTE: setting different properties for overflow-x and y does not work in Safari.
            // carousel still works, but overflow-y scrollbar may remain.
            & > div {
                flex: 1 0 100%;
                align-self: flex-start;
            }
        }

        // citation
        span.current-transcription,
        span.current-translation {
            display: block;
            @include typography.meta;
            color: var(--extra-metadata-text);
            &.multiple {
                min-height: calc(1rem * 1.5 * 2);
            }
        }

        @include breakpoints.for-tablet-landscape-up {
            span.current-transcription.multiple,
            span.current-translation.multiple {
                min-height: calc(1.125rem * 1.5 * 2);
            }
        }

        @include breakpoints.for-tablet-landscape-up {
            padding: 0 20px;
            // Label headers within transcription/translation
            h3 {
                margin-top: 12px;
                margin-bottom: 12px;
            }
        }
    }

    // Attribution and license text
    details.permissions {
        max-width: none;
        * {
            max-width: none;
        }
        margin: 0;
        background-color: var(--background-light);
        padding: 20px;
        align-self: flex-start;
        flex: 1 0 100%;
        text-align: left;
        // Label to expand "Image Permissions Statement"
        summary {
            cursor: pointer;
            display: flex;
            align-items: center;
            &:focus {
                outline: none;
                span {
                    outline: 0.1rem solid var(--focus);
                }
            }
            span {
                @include typography.link;
            }
            // Suppress default details marker
            list-style-type: none;
            &::-webkit-details-marker {
                display: none;
            }
            &::after {
                content: "\f0c2"; // phosphor caret-down icon
                @include typography.icon-button-md;
                transition: transform 0.75s ease;
                display: inline-block;
                text-decoration: none;
                margin-left: spacing.$spacing-xs;
            }
        }
        &[open] summary {
            // Expanded details marker
            &::after {
                transform: rotate(-180deg);
            }
            margin-bottom: 0.5rem;
        }
        @include typography.permissions-statement;
        a.shelfmark {
            @include typography.link;
        }
        a.shelfmark,
        span.shelfmark {
            @include typography.permissions-statement-bold;
        }
        // list of attributions/licenses
        ul {
            list-style: none;
            /* adjust for styles applied when embedded in admin document change form */
            margin-left: 0;
            padding-left: 0;
        }
        li.fragment-permissions {
            margin-top: spacing.$spacing-xs;
            // Rights statement image
            svg.rights-statement {
                height: 14px;
                width: 67px;
                fill: var(--on-background);
                vertical-align: text-top;
            }
            img {
                height: 16px;
                vertical-align: middle;
            }
        }
        // provider logos (interim solution)
        ul.logos {
            display: flex;
            flex-flow: row;
            &:not(:empty) {
                margin-top: spacing.$spacing-sm;
            }
            li img {
                width: auto;
                height: 4rem;

                /* manchester logo is white & black, and doesn't display properly
                in dark mode; simpler solution? chould we just set a background ? */
                &[src="https://princetongenizalab.github.io/iiif/jrl/umanchester_logo.png"]
                {
                    @media (prefers-color-scheme: dark) {
                        filter: invert(1);
                    }
                    .dark-mode & {
                        filter: invert(1);
                    }
                    .light-mode & {
                        filter: invert(0);
                    }
                }
            }

            li + li {
                margin-left: spacing.$spacing-xs;
            }
            li svg#jts {
                width: 8rem;
                height: 4rem;
                fill: var(--jts-logo);
            }
        }
        // spacing tweaks for desktop
        @include breakpoints.for-tablet-landscape-up {
            summary {
                justify-content: flex-start;
            }
            &[open] summary {
                margin-bottom: spacing.$spacing-md;
            }
            li.fragment-permissions {
                margin-top: 0.75rem;
                svg.rights-statement {
                    height: 16px;
                    width: 90px;
                }
            }
            & + div,
            & + div + div {
                height: 100%;
            }
        }
    }
}
.editor-navigation,
.editor-add {
    span {
        @include typography.link;
    }
    i {
        text-decoration: none;
    }
}
.editor-navigation {
    margin: -1rem 0 2rem;
}
.editor-add {
    margin: 0 0 1rem;
}
.editor-add + #itt-panel {
    margin-top: 2rem;
}

// general transcription/translation styles
// appear in ITT viewer and in search results
.transcription,
.translation {
    // section labels are always LTR
    h3 {
        direction: ltr;
    }

    .search-result & p {
        // disable site default max character width for reading
        max-width: none;
    }

    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
        top: -0.5em;
    }

    ol,
    section#document-list & ol {
        list-style: decimal;
        margin-top: 0;
    }

    /* cover the decimal in the list number to help with ltr/rtl readability */
    ol li:not([value]) {
        position: relative;
    }

    /* don't apply to li with value attribute in search results */
    ol li:not([value])::before {
        @include typography.transcription-numerals;
        /* content is a copy of ONLY the period we want to hide
           to avoid obscuring anything else should position be off */
        content: ".";
        text-align: left;
        position: absolute;
        width: 35px;
        /* text color should match background, rendering period invisible */
        color: var(--background-light);
        /* uncomment to debug positioning */
        // color: yellow;
    }

    // NOTE: Broken in stable Safari for now, but fixed in Safari preview release.
    li::marker {
        @include typography.transcription-numerals;
        font-size: typography.$text-size-md;
        @include breakpoints.for-tablet-landscape-up {
            font-size: typography.$text-size-xl;
        }
    }

    // RTL content (all transcriptions, some translations)
    &[dir="rtl"] {
        align-self: flex-end;

        text-align: right;
        // direction: rtl; /* search results are mixed rtl/ltr and include unicode order mark characters */

        .search-result & p {
            text-align: right;
        }

        li {
            margin-right: 1.5em;
            padding-right: 1em; /* shift to make space for line numbers */
            direction: rtl;
        }

        p {
            // match alignment of list items
            margin-right: 1em;
            padding-right: 1.5em;
        }

        li > p {
            // if inside a list item, don't pad <p>
            margin-right: 0;
            padding-right: 0;
        }

        ol li:not([value])::before {
            /* make font larger than list number font to cover completely;
            shrink line height to adjust placement */
            right: -35.75px;
            font-size: 37px;
            line-height: 22px;

            @include breakpoints.for-tablet-landscape-up {
                font-size: 45px;
                line-height: 25px;
                right: -36px;
            }
        }

        // NOTE: Broken in stable Safari for now, but fixed in Safari preview release.
        li::marker {
            text-align: right;
            float: right;
        }
    }

    &[dir="ltr"] {
        li {
            margin-left: 1.6em;
            padding-left: 1em; /* shift to make space for line numbers */
        }
        p {
            // match alignment of list items
            margin-left: 1em;
            padding-left: 1.6em;
        }
        li > p {
            // if inside a list item, don't pad <p>
            margin-left: 0;
            padding-left: 0;
        }
        ol li:not([value])::before {
            /* make font larger than list number font to cover completely;
                   shrink line height to adjust placement */
            font-size: 37px;
            line-height: 18px;
            left: -9.5px;

            @include breakpoints.for-tablet-landscape-up {
                font-size: 50px;
                line-height: 16px;
                left: -12px;
            }
        }
    }
}
.transcription {
    @include typography.transcription;
}
.transcription,
.translation {
    // arabic script
    &[lang="ar"],
    &[data-lang-script="arabic"],
    span[lang="ar"] {
        @include typography.arabic-transcription;
    }
    // hebrew script
    &[lang="he"],
    &[data-lang-script="hebrew"],
    span[lang="he"] {
        @include typography.transcription;
    }
}

// Manual (body-class based) theme overrides
html.light-mode #itt-panel {
    .panel-container
        div.img
        .img-header
        input[type="checkbox"][id^="zoom-toggle"] {
        &:checked + label[for^="zoom-toggle"]:after {
            background-image: url("/static/img/ui/mobile/light/all/deep-zoom-toggle-on.svg");
        }
    }
}
html.dark-mode #itt-panel {
    .panel-container
        div.img
        .img-header
        input[type="checkbox"][id^="zoom-toggle"] {
        &:checked + label[for^="zoom-toggle"]:after {
            background-image: url("/static/img/ui/mobile/dark/all/deep-zoom-toggle-on.svg");
        }
    }
}

// tweaks for RTL ITT panel for hebrew, arabic
html[dir="rtl"] #itt-panel {
    details.itt-select summary span::after {
        text-align: left;
    }
    input[type="checkbox"].toggle + label:first-of-type {
        margin-right: auto;
        margin-left: 9px;
        @include breakpoints.for-tablet-landscape-up {
            margin-left: 4px;
        }
    }
    input[type="checkbox"].toggle + label:last-of-type {
        margin-left: auto;
        margin-right: 9px;
        @include breakpoints.for-tablet-landscape-up {
            margin-right: 4px;
        }
    }
    .panel-container div.img .img-header {
        h2 {
            text-align: right;
        }
        label {
            text-align: left;
        }
        input[type="checkbox"][id^="zoom-toggle"] + label[for^="zoom-toggle"] {
            padding-right: 0;
            padding-left: 8px;
            &::after {
                margin-right: 8px;
                margin-left: 0;
            }
        }
    }
}
/**
 * Line-level editor style tweaks
 */
.tahqiq-line-group .tahqiq-block-display p {
    margin: 0;
    padding: 0;
}
.tahqiq-line-group .tahqiq-block-display::before {
    @include typography.transcription-numerals;
    font-size: typography.$text-size-md;
    @include breakpoints.for-tablet-landscape-up {
        font-size: typography.$text-size-xl;
    }
}
