// -----------------------------------------------------------------------------
// Content page styles
// -----------------------------------------------------------------------------

@use "../base/breakpoints";
@use "../base/fonts";
@use "../base/spacing";
@use "../base/typography";

main.content-page {
    @include breakpoints.for-tablet-landscape-up {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        justify-content: center;
        max-width: 872px;
        margin: 1.5rem auto 0;
        gap: 2.25rem;
    }
    @include breakpoints.for-xl-up {
        max-width: 1200px;
        gap: 4.5rem;
    }
    nav.pages-sidebar {
        display: none;
        @include breakpoints.for-tablet-landscape-up {
            display: block;
            a {
                display: flex;
                flex-flow: column;
                width: 100%;
                text-decoration: none;
                padding: 0.5rem 2.5rem 0.5rem 0;
                border-right: 4px solid var(--zoom-control-bg);
                text-wrap: nowrap;
                &.active {
                    border-right-color: var(--secondary);
                    @include typography.body-bold;
                }
                // hack to prevent bold text from changing width
                // via https://stackoverflow.com/a/32570813/394067
                &::after {
                    display: block;
                    content: attr(title);
                    @include typography.body-bold;
                    height: 1px;
                    color: transparent;
                    overflow: hidden;
                    visibility: hidden;
                }
            }
            a,
            a.active,
            a::after {
                font-size: typography.$text-size-xl;
            }
        }
        @include breakpoints.for-xl-up {
            a,
            a.active,
            a::after {
                font-size: typography.$text-size-3xl;
            }
        }
    }
    .container {
        // Overall spacing
        padding: spacing.$spacing-md spacing.$spacing-md;
        @include breakpoints.for-tablet-landscape-up {
            padding: 0;
            margin: 0;
            max-width: none;
            * {
                max-width: none;
            }
        }
    }
    @include breakpoints.for-tablet-landscape-up {
        h1 {
            font-size: typography.$text-size-7xl;
        }
    }
    details {
        border-top: 1px solid var(--zoom-control-bg);
        border-bottom: 1px solid var(--zoom-control-bg);
        width: 100%;
        max-width: 100%;
        summary {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            padding: 1rem 0;
            width: 100%;
            max-width: 100%;
            font-family: fonts.$primary-bold;
            font-weight: 700;
            font-size: typography.$text-size-3xl;
            line-height: calc(34 / 24);
            list-style-type: none;
            // Suppress default details marker
            &::-webkit-details-marker {
                display: none;
            }
            &::after {
                content: "\f27f"; // phosphor plus icon
                @include typography.icon-button-md;
                font-weight: 400;
                margin-left: 0.5rem;
            }
        }
        &[open] summary::after {
            content: "\f21a"; // phosphor minus icon
        }
    }
    .block-collapsible_paragraph ~ *:not(.block-collapsible_paragraph) {
        margin-top: spacing.$spacing-md;
    }
    .block-collapsible_paragraph + .block-collapsible_paragraph {
        details {
            border-top: none;
        }
    }
}
main.content-page,
main.homepage {
    h1 {
        margin-top: -#{spacing.$spacing-sm};
        @include breakpoints.for-tablet-landscape-up {
            margin-top: 0;
        }
    }
    .container {
        // List styles, nested inside container to prevent affecting nav lists
        ul,
        ol {
            margin: spacing.$spacing-md 0 spacing.$spacing-md
                spacing.$spacing-lg;
            li + li {
                margin-top: spacing.$spacing-md;
            }
        }
        ul li {
            position: relative;
            &::before {
                content: "";
                width: 16px;
                height: 18px;
                position: absolute;
                left: -#{spacing.$spacing-lg};
                display: block;
                background-color: var(--link-primary);
                mask-image: url("/static/img/ui/mobile/all/bullet.svg");
                @include breakpoints.for-tablet-landscape-up {
                    width: 18px;
                    height: 20px;
                    mask-image: url("/static/img/ui/desktop/all/bullet.svg");
                }
            }
        }
        ol {
            counter-reset: listitem;
            li::before {
                content: counter(listitem);
                counter-increment: listitem;
                float: left;
                padding-left: spacing.$spacing-2xs;
                margin-left: -#{spacing.$spacing-lg};
            }
        }
    }

    p {
        // Paragraph spacing unique to content pages
        margin-bottom: spacing.$spacing-md;
        // Mark for underilne
        mark.underline {
            // Prevent user agent stylesheet from treating all marks like highlights
            background-color: transparent;
            text-decoration: underline;
        }
        // Wagtail rich text editor uses b tag for bold
        b {
            @include typography.body-bold;
        }
        // Wagtail rich text editor uses i tag for italic
        i {
            @include typography.body-italic;
        }
    }

    // Image handling unique to content pages
    figure {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: spacing.$spacing-md 0;
        figcaption {
            align-self: flex-start;
            color: var(--on-background-alt);
            margin: spacing.$spacing-xs 0 0;
            p {
                margin: 0;
            }
        }
        img {
            align-self: center;
            // svg image styling
            max-width: 100%;
            max-height: 100%;
            // landscape image styling
            &.landscape {
                height: auto;
                max-width: 280px;
                @include breakpoints.for-tablet-landscape-up {
                    max-width: 400px;
                }
            }
            // portrait image styling
            &.portrait {
                width: auto;
                max-width: 100%;
                max-height: 200px;
                @include breakpoints.for-tablet-landscape-up {
                    max-height: 300px;
                }
            }
        }
    }
}

// RTL tweaks
html[dir="rtl"] main.content-page {
    .container {
        ul,
        ol {
            margin: spacing.$spacing-md spacing.$spacing-lg spacing.$spacing-md
                0;
        }
    }
    ul li {
        position: relative;
        &::before {
            left: auto;
            right: -#{spacing.$spacing-lg};
        }
    }
    ol {
        counter-reset: listitem;
        li::before {
            content: counter(listitem);
            counter-increment: listitem;
            float: right;
            padding-left: 0;
            margin-left: 0;
            padding-right: spacing.$spacing-2xs;
            margin-right: -#{spacing.$spacing-lg};
        }
    }
    nav.pages-sidebar {
        @include breakpoints.for-tablet-landscape-up {
            a {
                padding: 0.5rem 0 0.5rem 2.5rem;
                border-right: none;
                border-left: 4px solid var(--zoom-control-bg);
                &.active {
                    border-left-color: var(--secondary);
                }
            }
        }
    }
}

// TODO: Wagtail content should get lang attribute by block

// Hebrew variant
html[lang="he"] main.content-page,
html[lang="he"] main.homepage {
    p:not([lang="en"]) b,
    p:not([lang="en"]) i {
        @include typography.body-he;
    }
}

// Arabic variant
html[lang="ar"] main.content-page,
html[lang="ar"] main.homepage {
    p:not([lang="en"]) b,
    p:not([lang="en"]) i {
        @include typography.body-ar;
    }
}
html[lang="ar"] main.homepage {
    p:not([lang="en"]) {
        letter-spacing: -1px;
        margin-bottom: 1rem;
    }
}

html[lang="ar"] main.content-page {
    p:not([lang="en"]) {
        margin-bottom: 1.125rem;
    }
}
