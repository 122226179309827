// -----------------------------------------------------------------------------
// Document search form.
// -----------------------------------------------------------------------------

@use "../base/a11y";
@use "../base/breakpoints";
@use "../base/colors";
@use "../base/container";
@use "../base/fonts";
@use "../base/spacing";
@use "../base/typography";

// search form
main.search form {
    // search query box and button
    fieldset#query {
        margin-bottom: 1.5rem;
        // search mode switch label (hidden on mobile)
        span.fieldname {
            @include typography.headline-3;
            display: none;
            @include breakpoints.for-tablet-landscape-up {
                display: block;
                white-space: nowrap;
            }
        }
        // search mode help (hidden on mobile)
        button#search-help {
            display: none;
            @include breakpoints.for-tablet-landscape-up {
                display: flex;
                cursor: pointer;
                border: none;
                background: transparent;
                color: var(--on-background);
                padding: 0;
                margin-left: 0.5rem;
                &::before {
                    @include typography.icon-button-md;
                    font-size: typography.$text-size-2xl;
                    content: "\f1e0"; // phosphor info icon
                }
            }
        }
        dialog {
            background-color: var(--background);
            color: var(--on-background);
            border: none;
            padding: 1.5rem;
            box-shadow: 0px 4px 4px 0px #00000040;
            h2 {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;
                margin: 0;
                span {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                }
                span::before {
                    @include typography.icon-button-md;
                    font-weight: normal;
                    font-size: typography.$text-size-4xl;
                    content: "\f1e0"; // phosphor info icon
                }
                button {
                    display: flex;
                    cursor: pointer;
                    border: none;
                    background: transparent;
                    padding: 0;
                    &::before {
                        @include typography.icon-button-md;
                        font-size: typography.$text-size-4xl;
                        content: "\f36d"; // phosphor X icon
                    }
                }
            }
            a {
                font-family: fonts.$primary-bold;
                font-weight: 600;
            }
            h3 {
                font-size: typography.$text-size-lg;
                padding-top: 1.5rem;
                margin-top: 1.5rem;
                margin-bottom: 0.5rem;
                border-top: 1px solid var(--background-light);
            }
            p,
            li,
            h4 {
                font-size: typography.$text-size-md;
            }
            h4 {
                margin-top: 1.5rem;
            }
            ul {
                list-style: disc;
                li {
                    margin-left: 1.5rem;
                }
            }
            code {
                background-color: var(--background-light);
                border-radius: 3px;
                font-family: courier, monospace;
                padding: 0 3px;
            }
        }
        // search mode switch buttons (hidden on mobile)
        ul#id_mode {
            display: none;
            @include breakpoints.for-tablet-landscape-up {
                display: flex;
                flex-flow: row nowrap;
                background-color: var(--background-light);
                border: 1px solid var(--background-gray);
                border-radius: 50px;
                align-items: center;
                margin: 0 3.25rem 0 1.5rem;
                input {
                    @include a11y.sr-only;
                }
                label {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    padding: 0 1rem;
                    height: 2.5rem;
                    border-radius: 50px;
                    cursor: pointer;
                    border: 2px solid transparent;
                    transition: 0.15s border-color ease-in-out;
                    &:hover {
                        border-color: var(--disabled);
                    }
                    &:has(input:checked) {
                        @include typography.body-bold;
                        background-color: var(--on-primary);
                        border-color: var(--secondary);
                        &:hover {
                            border-color: var(--primary);
                        }
                    }
                    &:has(*:focus-visible) {
                        outline: 2px solid var(--button-focus);
                    }
                }
            }
        }
    }
    // dropdown in facet filters
    #filters label:has(select) {
        position: relative;
        select {
            border-radius: 5px;
            height: 40px;
            width: 260px;
            cursor: pointer;
            background-color: var(--background-light);
            border: 1px solid var(--background-gray);
            font-family: fonts.$primary;
            font-weight: 400;
            font-size: typography.$text-size-md;
            margin: 0.25rem 0 0.25rem 1.75rem;
            padding: 0 1rem;
            -webkit-appearance: none;
            appearance: none;
            &:disabled {
                cursor: default;
            }
        }
        &::after {
            position: absolute;
            @include typography.icon-button-sm;
            content: "\f0c2"; // phosphor caret-down icon
            top: 0.6rem;
            right: 1rem;
            pointer-events: none;
        }
        &:has(select:disabled)::after {
            color: var(--disabled);
        }
    }
    // error messages
    ul#search-errors {
        margin-top: spacing.$spacing-md;
        color: red;
        li {
            @include typography.meta;
        }
    }
}

main.search,
main.people {
    // Form with filters
    form {
        display: flex;
        flex-flow: column;
        width: 100%;
        padding: 0;
    }
    fieldset#query {
        display: flex;
        align-items: center;
    }
    // search box
    input[type="search"] {
        flex-basis: 100%;
        border: none;
        border-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid var(--background-gray);
        border-right: none;
        height: 3rem;
        padding: 0 1rem;
        @include typography.body;
        background-color: var(--background-light);
        color: var(--on-background-light);
        @include breakpoints.for-tablet-landscape-up {
            height: 2.5rem;
        }
    }
    button[type="submit"] {
        cursor: pointer;
        width: 3rem;
        height: 3rem;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        margin-left: 0; // Needed to prevent wrapping on mobile
        background-color: var(--secondary);
        color: var(--on-secondary);
        @include breakpoints.for-tablet-landscape-up {
            height: 2.5rem;
            min-width: 2.5rem;
        }
        // Magnifying glass icon
        &::after {
            content: "\f5e6"; // phosphor magnifying glass bold icon
            @include typography.icon-button-md;
            @include breakpoints.for-tablet-landscape-up {
                @include typography.icon-button-sm;
            }
        }
    }
    div#filters-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0.25rem 0;
        gap: 1.5rem;
        &:has(#applied-filters) {
            border-top: 1px solid var(--background-gray);
            border-bottom: 1px solid var(--background-gray);
        }
        &:not(:has(#applied-filters)) {
            height: 0;
            padding: 0;
            @include breakpoints.for-tablet-landscape-up {
                height: auto;
                padding: 0.25rem 0;
                border-top: 1px solid var(--background-gray);
                border-bottom: 1px solid var(--background-gray);
            }
        }
        // filter chips container
        #applied-filters {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            gap: 0.25rem 0.5rem;
            padding: 0;
            @include breakpoints.for-tablet-landscape-up {
                flex: 1 1 100%;
                padding: 0 1.5rem;
            }
            &::before {
                content: "";
                height: 1rem;
                position: absolute;
                margin-left: -1.5rem;
                border-left: 1px solid colors.$gray;
            }
            // filter chip
            button {
                border: none;
                outline: none;
                cursor: pointer;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                height: 2rem;
                padding: 0 1rem;
                @include typography.form-option-xs;
                line-height: 1.25;
                color: var(--on-background-gray);
                background-color: var(--background-gray);
                transition: background-color 0.15s ease-in-out,
                    color 0.15s ease-in-out;
                &:hover {
                    background-color: var(--filter-chip-bg-hover);
                }
                &:active {
                    background-color: var(--filter-chip-bg-active);
                }
            }
        }
        button#clear-filters {
            display: none;
            @include breakpoints.for-tablet-landscape-up {
                display: block;
            }
            cursor: pointer;
            color: var(--disabled-on-background-light);
            background-color: transparent;
            border: none;
            height: 2rem;
            min-width: 4.5rem;
            @include typography.meta;
            transition: color 0.15s ease-in-out;
            &:hover {
                color: var(--date-placeholder);
            }
            &:active {
                color: var(--on-background);
            }
        }
    }
    // button for opening and closing the filter panel/modal
    a#filters-button {
        text-decoration: none;
        @include typography.meta-bold;
        display: flex;
        align-items: center;
        min-height: 48px;
        padding: 0 0.5rem;
        border-radius: 5px;
        position: absolute;
        top: 9rem;
        right: 1rem;
        align-self: flex-start;
        flex: 0 1 auto;
        margin: auto 0;
        @include breakpoints.for-tablet-landscape-up {
            position: relative;
            top: auto;
            right: auto;
            padding: 0 2rem 0 0.5rem;
            span:last-of-type:after {
                content: "\f0c2"; // phosphor caret-down icon
            }
            &:has(~ fieldset#filters:target),
            &:has(~ fieldset#filters[aria-expanded="true"]) {
                // prevent display and focus when expanded, maintain flow/spacing
                visibility: hidden;
            }
        }
        svg {
            width: 20px;
            height: 20px;
        }
        &:focus {
            outline: 0.125rem solid var(--focus);
        }
        // text that reads "Filters"
        span {
            position: relative;
            margin-left: 0.25rem;
            display: flex;
            align-items: center;
            // caret to indicate opening and closing
            &:last-of-type:after {
                position: absolute;
                font-family: "Phosphor";
                font-size: typography.$text-size-md;
                right: -1.5rem;
                font-weight: 600;
            }
        }
        // count of active filters
        span.filter-count {
            justify-content: center;
            min-width: 1.25rem;
            height: 1.25rem;
            font-family: fonts.$primary;
            font-weight: 400;
            font-size: typography.$text-size-md;
            background-color: var(--background-gray);
            border-radius: 0.25rem;
        }
        // close button
        &.open {
            display: none;
            @include breakpoints.for-tablet-landscape-up {
                display: flex;
                background-color: var(--secondary);
                color: var(--on-secondary);
                fill: var(--on-secondary);
                span:last-of-type:after {
                    content: "\f0c5"; // phosphor caret-up icon
                }
                span.filter-count {
                    background-color: var(--primary);
                }
            }
        }
    }
    a#close-filters-modal {
        text-decoration: none;
        position: absolute;
        right: 0.25rem;
        top: 0.25rem;
        @include typography.icon-button-md;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before {
            content: "\f36d"; // phosphor X icon
        }
        @include breakpoints.for-tablet-landscape-up {
            display: none;
        }
    }
    // filter modal backdrop (mobile)
    .modal-backdrop:has(~ fieldset#filters:target:not([aria-expanded="false"])),
    .modal-backdrop:has(~ fieldset#filters[aria-expanded="true"]) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 7;
        min-width: 100vw;
        min-height: 100vh;
        background-color: var(--modal-backdrop);
        @include breakpoints.for-tablet-landscape-up {
            display: none;
        }
    }
}

main.search form fieldset#filters div.fieldset-left-column {
    @include breakpoints.for-tablet-landscape-up {
        gap: 1rem;
    }
    label[for="id_exclude_inferred"] {
        position: relative;
        cursor: pointer;
        input[type="checkbox"] + span::before {
            display: none;
        }
        input[type="checkbox"] + span {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
        input[type="checkbox"] + span::after {
            display: block;
            content: "";
            border-radius: 7px;
            width: 34px;
            height: 14px;
            background-color: var(--background-gray);
            transition: background-color 0.1s ease-in-out;
        }
        input[type="checkbox"]:active + span::after,
        input[type="checkbox"]:checked + span::after {
            background-color: var(--secondary-40);
        }
        input[type="checkbox"]:checked:active + span::after {
            background-color: var(--background-gray);
        }
        .thumb {
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: calc(50% - 10px);
            right: 14px;
            transition: right 0.1s ease-in-out,
                background-color 0.1s ease-in-out;
            border-radius: 50%;
            background-color: var(--disabled-on-background-light);
            box-shadow: 0px 1px 3px 0px #00000033;
            box-shadow: 0px 2px 1px 0px #0000001f;
            box-shadow: 0px 1px 1px 0px #00000024;
        }
        input[type="checkbox"]:active + span + .thumb,
        input[type="checkbox"]:checked + span + .thumb {
            right: 0;
            background-color: var(--secondary);
        }
    }
}

// tweaks for RTL search form for hebrew, arabic
html[dir="rtl"] main.search form {
    // search query box and button
    fieldset#query {
        // search box
        input[type="search"] {
            border-radius: 5px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
            border-right: 1px solid var(--background-gray);
        }
        button[type="submit"] {
            border-radius: 5px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            margin-left: 0;
            margin-right: 0;
        }
        button#search-help {
            margin-left: 0;
            margin-right: 0.5rem;
        }
        dialog ul li {
            margin-left: 0;
            margin-right: 1.5rem;
        }
        ul#id_mode {
            margin: 0 1.5rem 0 3.25rem;
        }
    }
    // dropdown in facet filters
    #filters label:has(select) {
        select {
            margin-right: 1.75rem;
            margin-left: 0;
        }
        &::after {
            right: auto;
            left: 1rem;
        }
    }
}

// Hebrew variant
html[lang="he"] main.search form {
    a#filters-button:not([lang="en"]),
    a#close-filters-button:not([lang="en"]) {
        @include typography.body-he;
    }
    fieldset#filters > label:not(.date-range-label) span.count,
    ul#search-errors li {
        @include typography.meta-he;
    }
}

// Arabic variant
html[lang="ar"] main.search form {
    a#filters-button:not([lang="en"]),
    a#close-filters-button:not([lang="en"]) {
        @include typography.body-ar;
    }
    fieldset#filters > label:not(.date-range-label) span.count,
    ul#search-errors li {
        @include typography.meta-ar;
    }
}
