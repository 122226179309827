// -----------------------------------------------------------------------------
// Lists of detailed metadata about Geniza documents.
// -----------------------------------------------------------------------------

@use "../base/spacing";
@use "../base/typography";

dl.metadata-list {
    display: grid;
    @include typography.meta;
    dd,
    dt {
        word-break: break-word;
    }
}

// Hebrew variant
html[lang="he"] dl.metadata-list {
    @include typography.meta-he;
    *[lang="en"] {
        // handle any dt/dd in English
        @include typography.meta;
    }
}

// Arabic variant
html[lang="ar"] dl.metadata-list {
    @include typography.meta-ar;
    *[lang="en"] {
        // handle any dt/dd in English
        @include typography.meta;
    }
}
