// -----------------------------------------------------------------------------
// Shared document header content (title and edit link).
// -----------------------------------------------------------------------------

@use "../base/breakpoints";
@use "../base/container";
@use "../base/spacing";
@use "../base/typography";

// Document title
span#formatted-title {
    z-index: 2; // prevent edit container from overlapping
    @include typography.headline-1;
    // document type, inside title
    .doctype {
        @include typography.doctype;
    }
    .shelfmark {
        user-select: all; // allow one-click select
        @include typography.shelfmark;
    }
    margin: 2rem spacing.$spacing-md spacing.$spacing-md;
    font-size: typography.$text-size-xl;
    @include breakpoints.for-tablet-landscape-up {
        margin: 3.5rem spacing.$spacing-md 2rem;
        font-size: typography.$text-size-3xl;
    }
}
// Hebrew variant
html[lang="he"] span#formatted-title {
    @include typography.headline-1-he;
}
// Arabic variant
html[lang="ar"] span#formatted-title {
    @include typography.headline-1-ar;
}

// Edit link for admins
.edit-link-container {
    max-width: calc(896px + 2rem);
    top: spacing.$spacing-4xl;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @include breakpoints.for-tablet-landscape-up {
        top: auto;
    }
    a.edit-link {
        span {
            @include typography.link;
        }
        i {
            text-decoration: none;
        }
        margin-right: spacing.$spacing-xl;
        @include breakpoints.for-tablet-landscape-up {
            margin: spacing.$spacing-xl spacing.$spacing-md;
        }
    }
}
