// -----------------------------------------------------------------------------
// Lists of search results for documents.
// -----------------------------------------------------------------------------

@use "../base/breakpoints";
@use "../base/container";
@use "../base/spacing";
@use "../base/typography";
@use "../base/colors";

section#document-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0 spacing.$spacing-md;

    // count of results
    h1 {
        @include typography.caption;
        text-align: center;
        margin: spacing.$spacing-sm 0;
    }
    // list of results
    ol {
        list-style: none;
        margin-top: spacing.$spacing-md;
        @include breakpoints.for-tablet-landscape-up {
            margin-top: spacing.$spacing-lg;
        }
    }
    nav.pagination + ol {
        margin-top: 0;
        @include breakpoints.for-tablet-landscape-up {
            margin-top: spacing.$spacing-md;
        }
    }
    a#apd {
        text-align: center;
        margin: 1.5rem 0;
        @include breakpoints.for-tablet-landscape-up {
            margin: 2.25rem 0;
        }
    }
    nav.pagination + a#apd {
        margin: 0;
        @include breakpoints.for-tablet-landscape-up {
            margin: 0;
        }
    }
}

// single result
.search-result {
    background-color: var(--background-light);
    transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: spacing.$spacing-md;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    position: relative;
    @include typography.body-sm;

    @include breakpoints.for-tablet-landscape-up {
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 1.5rem;
    }

    // spacing in between results
    & + .search-result {
        margin-top: spacing.$spacing-2xl;
        .score {
            top: 1.5rem;
        }
    }

    // relevance score if enabled
    .score {
        @include typography.meta-bold;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background-color: var(--background-gray);
        padding: 0.25rem;
        border: 1px solid var(--on-background);
    }

    // result number in list
    .counter {
        margin-right: 0.75rem;
        padding-right: 0.75rem;
        border-right: 1px solid var(--searchresult-separator);
    }

    // document titles
    .title {
        color: var(--on-background);
        transition: color 0.15s ease-in-out;
        position: relative;
        border-bottom: 1px solid var(--searchresult-separator);
        flex: 1 0 90%;
        padding-bottom: 0.75rem;
        margin-bottom: 0.75rem;
        font-size: typography.$text-size-lg;
        line-height: calc(24 / 18);
        @include breakpoints.for-tablet-landscape-up {
            font-size: typography.$text-size-xl;
            // TODO: figure out margin if counter numbers are staying
            // margin-left: spacing.$spacing-md;
            padding-bottom: spacing.$spacing-md;
            margin-bottom: spacing.$spacing-md;
            line-height: calc(24 / 20);
        }

        // document type, inside title
        .doctype {
            @include typography.doctype;
        }
        .shelfmark {
            @include typography.shelfmark;
        }
    }
    // First row on mobile, left column on larger
    section:first-of-type {
        max-width: none;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        flex: 1 1 70%;
        @include breakpoints.for-tablet-landscape-up {
            flex: 1 0 60%;
            align-self: flex-start;
            padding-right: spacing.$spacing-sm;
            // TODO: figure out margin if counter numbers are staying
            // margin-left: spacing.$spacing-md;
        }

        // other document metadata
        dl.metadata-list {
            grid-template-columns: auto 1fr;
            column-gap: spacing.$spacing-md;
            dt {
                @include typography.meta-label;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }

        // document description, transcription, translation margins/highlighting
        .description,
        .transcription,
        .translation {
            margin: spacing.$spacing-md 0;
            max-width: none;

            // keywords in context
            em {
                color: var(--secondary);
                font-weight: bold;
                font-style: normal;
            }
            /* solr returns multiple matches in text without space between */
            em + em {
                &::before {
                    content: " ";
                }
            }
        }

        .transcription {
            @include typography.transcription-search;
        }
        .transcription,
        .translation {
            // arabic script
            &[lang="ar"],
            &[data-lang-script="arabic"],
            span[lang="ar"] {
                @include typography.arabic-transcription-search;
            }
            // hebrew script
            &[lang="he"],
            &[data-lang-script="hebrew"],
            span[lang="he"] {
                @include typography.transcription-search;
            }
        }

        // related documents and entities
        dl.related {
            @include typography.meta;
            border-top: 1px solid var(--searchresult-separator);
            padding-top: 1.25rem;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: spacing.$spacing-md;
            dt {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                gap: 0.5rem;
                @include typography.caption;
                &::before {
                    font-style: normal;
                    @include typography.icon-button-sm;
                }
                &.documents::before {
                    content: "\edf2";
                }
                &.people::before {
                    content: "\f346";
                }
                &.places::before {
                    content: "\f20d";
                }
            }
            dd + dt {
                border-left: 1px solid var(--disabled-on-background-light);
                padding-left: spacing.$spacing-md;
            }
        }

        // document tags
        ul.tags {
            padding-top: spacing.$spacing-md;
            border-top: 1px solid var(--searchresult-separator);
        }
        dl.related ~ ul.tags {
            border: none;
        }

        // document scholarship records
        .scholarship {
            @include typography.meta;
            display: flex;
            flex-flow: row wrap;
            padding: 0.5rem;
            span {
                &:last-child {
                    padding-right: 0;
                }
                padding-right: spacing.$spacing-xs;
                @include breakpoints.for-tablet-landscape-up {
                    padding-right: spacing.$spacing-md;
                }
            }
            span + span {
                border-left: 1px solid var(--disabled-on-background-light);
                padding-left: spacing.$spacing-xs;
                @include breakpoints.for-tablet-landscape-up {
                    padding-left: spacing.$spacing-md;
                }
            }
            border-top: 1px solid var(--searchresult-separator);
            border-bottom: 1px solid var(--searchresult-separator);
            margin-top: 1.25rem;
        }
    }

    .right-column {
        position: relative;
        display: flex;
        flex-flow: column;
        @include breakpoints.for-tablet-landscape-up {
            flex: 1 1 31%;
        }
        &:has(.no-image) {
            align-items: flex-end;
        }
    }
    // document images
    ul.images {
        position: relative;
        display: flex;
        flex-flow: column;
        text-align: center;
        overflow-x: visible;
        overflow-y: clip;
        img {
            max-width: 170px;
            max-height: 250px;
            width: auto;
        }
        li {
            width: 170px;
            padding: 10px 0;
            margin: 0 auto;
            z-index: 3;
            & + li {
                position: absolute;
                z-index: 2;
                top: 5%;
                left: 7.5%;
                img {
                    max-width: 160px;
                    max-height: 240px;
                    opacity: 0.8;
                }
            }
            & + li + li {
                z-index: 1;
                top: 10%;
                left: 12.5%;
                img {
                    max-width: 150px;
                    max-height: 230px;
                    opacity: 0.3;
                }
            }
        }
        @include breakpoints.for-tablet-landscape-up {
            img {
                max-width: 100%;
                max-height: 300px;
            }
            li {
                width: 100%;
                & + li {
                    left: 5%;
                    img {
                        max-width: 90%;
                        max-height: 300px;
                    }
                }
                & + li + li {
                    left: 10%;
                    img {
                        max-width: 80%;
                        max-height: 300px;
                    }
                }
            }
        }
    }
    span.no-image {
        width: 250px;
        height: 250px;
        background-image: url("/static/img/ui/all/all/no-image-square-lg.png");
        background-size: 250px;
        font-weight: 600;
        font-size: typography.$text-size-6xl;
        margin-top: spacing.$spacing-sm;
        @include breakpoints.for-tablet-landscape-up {
            margin-top: 0;
        }
    }
    /* use pseudo marker to get transcription line numbers from snippets */
    .transcription li,
    .translation li {
        list-style-type: none; /* hide automatic ol numbering */
        &::before {
            @include typography.transcription-numerals;
            content: attr(value);
            height: 100%;
        }
        &:empty::before,
        &:has(p:empty)::before,
        &:has(em:empty)::before {
            display: none;
        }
    }
    .transcription[dir="ltr"] li::before,
    .translation[dir="ltr"] li::before {
        margin-left: -2rem;
        text-align: left;
        float: left;
    }
    .transcription[dir="rtl"] li,
    .translation[dir="rtl"] li {
        direction: rtl;
        &::before {
            margin-right: -2rem; /* shift outside text margin */
            text-align: right;
            float: right;
        }
    }
    .transcription[dir="ltr"] > p,
    .translation[dir="ltr"] > p {
        @include breakpoints.for-phone-only {
            margin-left: 0;
            padding-left: 0;
        }
    }

    /* set height on empty transcription/translation li to prevent overlap issue */
    .translation li:empty {
        height: calc(1.125rem * 1.5);
        @include breakpoints.for-tablet-landscape-up {
            height: calc(1.25rem * 1.5);
        }
    }
    .transcription li:empty {
        height: calc(27 / 20 * 1.25rem);
        @include breakpoints.for-tablet-landscape-up {
            height: calc(32 / 22 * 1.375rem);
        }
    }

    /* raise tags & text over clickable pseudo content to allow selection */
    .description,
    .transcription,
    .translation,
    dl.metadata-list dd,
    .tags {
        position: relative;
        z-index: 4;
    }
    /* make the whole result block clickable */
    a.view-link:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .pgp-metadata {
        display: flex;
        flex: 1 0 auto;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 0.25rem;
        dt[lang="en"]::before {
            content: "(";
        }
        dt[lang="en"] + dd::after {
            content: ")";
        }
    }
    .pgp-metadata:not(:empty),
    .pgp-metadata + .additional-metadata:not(:empty) {
        margin-top: spacing.$spacing-md;
    }
    .additional-metadata {
        text-align: right;
        dt {
            @include typography.meta-bold;
            &::after {
                content: ":";
            }
        }
    }
    &:hover {
        background-color: var(--background-gray);
        box-shadow: 0px 2px 6px 0px #00000040;
    }
    &:has(a.view-link:hover) {
        .title {
            color: var(--primary);
        }
    }
}

// "view document details" link
a.view-link {
    display: flex;
    flex: 1 0 100%;
    width: max-content;
    margin-left: auto;
    justify-content: flex-end;
    z-index: 3;
    &:has(span:not(.sr-only)) {
        margin-top: spacing.$spacing-md;
        height: 1.75rem;
    }
    & > span {
        display: flex;
        flex: 0 1 auto;
        align-items: center;
        @include typography.meta;
        border-bottom: 3px solid var(--background);
        color: var(--icon-button);
    }
    // focus styles
    &:focus > span {
        outline: 0.125rem solid var(--focus);
        outline-offset: 0.66rem;
    }
    // hover and active styles
    &:hover > span,
    &:active > span {
        outline: none; // keeping focus and hover/active styles distinct
    }
    &:hover > span {
        border-bottom-color: var(--icon-button);
        @include breakpoints.for-tablet-landscape-up {
            border-bottom-color: var(--icon-button-hover);
        }
    }
    &:active > span {
        color: var(--icon-button-active);
        border-bottom-color: var(--icon-button);
    }
    // icon to the right
    & > span:after {
        font-family: "Phosphor" !important;
        content: "\f044"; // phosphor arrow-right icon
        margin-left: spacing.$spacing-2xs;
        font-size: typography.$text-size-xl;
        @include breakpoints.for-tablet-landscape-up {
            margin-left: spacing.$spacing-sm;
            font-size: typography.$text-size-2xl;
        }
    }
}

// tweaks for RTL search results for hebrew, arabic
html[dir="rtl"] .search-result {
    // spacing in between results
    & + .search-result:before {
        // separator image (mobile)
        transform: scale(-1, 1);
        // separator image (desktop)
        @include breakpoints.for-tablet-landscape-up {
            margin-right: spacing.$spacing-2xl;
            margin-left: 0;
            right: auto;
            transform: scale(-1, 1);
        }
    }
    // result number in list
    .counter {
        margin-right: 0;
        padding-right: 0;
        margin-left: 0.75rem;
        padding-left: 0.75rem;
        border-right: none;
        border-left: 1px solid var(--searchresult-separator);
    }
    // First row on mobile, left column on larger
    section:first-of-type {
        // TODO: figure out margin if counter numbers are staying
        // margin-right: spacing.$spacing-md;
        margin-left: 0;
        @include breakpoints.for-tablet-landscape-up {
            padding-right: 0;
            padding-left: spacing.$spacing-sm;
        }
        // document scholarship records
        .scholarship {
            span {
                padding-right: 0;
                &:last-child {
                    padding-left: 0;
                }
                padding-left: spacing.$spacing-xs;
                @include breakpoints.for-tablet-landscape-up {
                    padding-left: spacing.$spacing-md;
                }
            }
            span + span {
                border-left: none;
                border-right: 1px solid var(--disabled-on-background-light);
                padding-right: spacing.$spacing-xs;
                @include breakpoints.for-tablet-landscape-up {
                    padding-right: spacing.$spacing-md;
                }
            }
        }
        dl.related {
            dd + dt {
                border-left: none;
                padding-left: 0;
                border-right: 1px solid var(--disabled-on-background-light);
                padding-right: spacing.$spacing-md;
            }
        }
    }
    // document images
    ul.images {
        li {
            & + li {
                left: auto;
                right: 7.5%;
            }
            & + li + li {
                left: auto;
                right: 12.5%;
            }
        }
        @include breakpoints.for-tablet-landscape-up {
            li {
                & + li {
                    left: auto;
                    right: 5%;
                }
                & + li + li {
                    left: auto;
                    right: 10%;
                }
            }
        }
    }
    // "view document details" link
    a.view-link {
        margin-left: 0;
        margin-right: auto;
        // icon to the left
        span:after {
            content: "\f03b"; // phosphor arrow-right icon
            margin-left: 0;
            margin-right: spacing.$spacing-2xs;
            @include breakpoints.for-tablet-landscape-up {
                margin-left: 0;
                margin-right: spacing.$spacing-sm;
            }
        }

        /* make the whole result block clickable */
        &:before {
            left: auto;
            right: 0;
        }
    }
    .transcription[dir="rtl"],
    .translation[dir="rtl"] {
        align-self: flex-start;
    }
    .transcription[dir="ltr"],
    .translation[dir="ltr"] {
        align-self: flex-end;
    }
    dt[lang="en"] {
        line-height: calc(19.4 / 14);
        font-size: typography.$text-size-sm;
        @include breakpoints.for-tablet-landscape-up {
            line-height: calc(24.3 / 18);
            font-size: typography.$text-size-lg;
        }
    }
    // relevance score if enabled
    .score {
        right: auto;
        left: 0;
    }
}

// Hebrew variant
html[lang="he"] {
    .search-result section:first-of-type .scholarship,
    a.view-link > span {
        @include typography.meta-he;
    }
}

// Arabic variant
html[lang="ar"] {
    .search-result section:first-of-type .scholarship,
    a.view-link > span {
        @include typography.meta-ar;
    }
}
