// -----------------------------------------------------------------------------
// Utility classes for controlling the width of elements.
// -----------------------------------------------------------------------------

@use "../base/breakpoints";
@use "spacing";

// Maximum width of a line of text, in characters. This is based on standards
// of readability and determines the width of containers on the page. See:
// https://every-layout.dev/rudiments/axioms/#measure
$measure: 60ch;

// Maximum width of a two-column layout, whose columns should not exceed
// width of $measure
$two-column: 900px;
@mixin two-column {
    max-width: $measure;
    @include breakpoints.for-tablet-landscape-up {
        max-width: $two-column;
    }
}
// Minimum amount of space left on either side of the page to create a margin.
$gutter: spacing.$spacing-sm;

// Utility class for applying the measure to elements that normally would not
// be constrained by it, such as <div>. Also applies the gutter.
.container {
    width: 100%;
    max-width: $measure;
    margin-left: $gutter;
    margin-right: $gutter;
}

// Mixin for applying the measure to components, so that they don't need another
// class to be applied.
@mixin measure {
    max-width: $measure;
}
