// -----------------------------------------------------------------------------
// Document detail page.
// -----------------------------------------------------------------------------

@use "../base/breakpoints";
@use "../base/colors";
@use "../base/container";
@use "../base/spacing";
@use "../base/typography";

@mixin meta-marker {
    // marker next to metadata headings
    content: " ";
    display: inline-block;
    width: 8px;
    height: 18px;
    background-color: var(--secondary);
    margin-right: 12px;
}

main.document {
    max-width: 100vw;
    @include breakpoints.for-tablet-landscape-up {
        .container,
        .container *,
        section,
        section * {
            max-width: calc(896px + 2rem);
        }
    }
    // Primary container
    .container {
        display: flex;
        flex-direction: column;
        padding: 0 spacing.$spacing-md 0;
        section.metadata {
            display: flex;
            flex-direction: column;
            @include breakpoints.for-tablet-landscape-up {
                margin-top: 2.5rem;
                flex-direction: row;
                justify-content: flex-start;
            }
        }
        // Document metadata
        dl.metadata-list {
            display: flex;
            flex-direction: column;
            margin: 1.375rem 0 0;
            @include typography.meta;
            @include breakpoints.for-tablet-landscape-up {
                flex: 1 1 50%;
                padding-right: 0;
                margin: 0 0 0;
            }
            dt {
                margin-bottom: spacing.$spacing-3xs;
                @include typography.meta-header;
                display: flex;
                align-items: center;
                &::before {
                    @include meta-marker;
                }
            }
            dd {
                margin-left: 20px;
            }
            dd + dt {
                margin-top: spacing.$spacing-sm;
                @include breakpoints.for-tablet-landscape-up {
                    margin-top: spacing.$spacing-md;
                }
            }
            dd + dd {
                margin-top: spacing.$spacing-xs;
            }
        }
        dl.metadata-list.primary + dl.metadata-list.secondary {
            margin-top: 0;
            @include breakpoints.for-tablet-landscape-up {
                margin-left: 35px;
            }
            > :first-child {
                margin-top: spacing.$spacing-sm;
                @include breakpoints.for-tablet-landscape-up {
                    margin-top: 0px;
                }
            }
        }
        // Document tags
        ul.tags {
            margin: 2rem 0 0;
            align-self: flex-start;
            text-align: left;
            border-bottom: 1px solid var(--disabled);
            padding-bottom: 2rem;
            @include breakpoints.for-tablet-landscape-up {
                flex: 1 0 100%;
                padding-left: 0;
                margin: 2rem 0 0;
                align-self: flex-start;
                margin-left: 0;
            }
        }
        // "what's in the PGP", "description", "related people/places"
        section.content-stats,
        section.description,
        section.related {
            margin: spacing.$spacing-md 0 0;
            @include breakpoints.for-tablet-landscape-up {
                margin: 2rem 0 0;
            }
            h2 {
                @include typography.meta-header;
                display: flex;
                align-items: center;
                margin: 0;
                &::before {
                    @include meta-marker;
                }
            }
        }
        // what's in the pgp
        section.content-stats ul {
            display: flex;
            flex-flow: row wrap;
            margin-left: 1.25rem;
            gap: 1rem;
            @include breakpoints.for-tablet-landscape-up {
                gap: 1.5rem;
            }
            li + li {
                border-left: 1px solid var(--disabled);
                padding-left: 1rem;
                @include breakpoints.for-tablet-landscape-up {
                    padding-left: 1.5rem;
                }
            }
            li {
                display: flex;
                align-items: center;
                gap: 0.625rem;
                &::before {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    background-color: var(--background-light);
                    width: 1.5rem;
                    height: 1.5rem;
                    @include breakpoints.for-tablet-landscape-up {
                        width: 2rem;
                        height: 2rem;
                    }
                }
            }
            li.has-image::before {
                content: "\f1dd"; // phosphor ph-image icon
                @include typography.icon-button-sm;
                @include breakpoints.for-tablet-landscape-up {
                    @include typography.icon-button-md;
                }
            }
            li.transcription-count::before {
                content: "a";
                font-size: typography.$text-size-xl;
                @include breakpoints.for-tablet-landscape-up {
                    font-size: typography.$text-size-4xl;
                    padding-bottom: 2px;
                }
            }
            li.translation-count::before {
                content: "א";
                @include typography.transcription;
                @include breakpoints.for-tablet-landscape-up {
                    font-size: typography.$text-size-4xl;
                    padding-bottom: 2px;
                }
            }
        }
        // Description
        section.description {
            padding-bottom: 2rem;
            border-bottom: 1px solid var(--disabled);
            p {
                white-space: pre-line;
                padding-left: 1.25rem;
            }
        }
        // related entities
        section.related {
            padding-bottom: 2rem;
            border-bottom: 1px solid var(--disabled);
        }
        // link to download transcription
        section.transcription-link {
            margin-top: spacing.$spacing-md;
            @include breakpoints.for-tablet-landscape-up {
                margin-top: spacing.$spacing-xl;
            }
        }
    }
    dl.metadata-list.tertiary {
        padding: 0 1rem;
        max-width: 896px;
        @include breakpoints.for-tablet-landscape-up {
            padding: 0;
            width: 896px;
        }
    }
    // Additional metadata section, like input date ("In PGP since") and historical shelfmark
    section.extra-metadata {
        width: 100%;
        padding: 0 spacing.$spacing-md;
        margin: 2rem 0;
        @include breakpoints.for-tablet-landscape-up {
            width: 896px;
            margin: 2rem 0;
            padding: 0;
        }
        @include typography.body;
        color: var(--extra-metadata-text);
        dl {
            display: grid;
            grid-template-columns: 3fr 7fr;
            gap: 0.75rem;
            dt {
                @include typography.body-bold;
            }
        }
    }
}

main.document,
main.place {
    // related entities
    section.related {
        dl {
            margin: 0 0 0 1.25rem;
            dt {
                padding-top: 0.5rem;
                @include typography.body-italic;
            }
            dd + dt {
                padding-top: 1rem;
                margin-top: 1rem;
                border-top: 1px solid var(--tabs-bottom);
            }
            dd {
                @include typography.body-sm;
            }
            @include breakpoints.for-tablet-landscape-up {
                display: grid;
                grid-template-columns: 33% 1fr;
                margin: 1.25rem 0 0 1.25rem;
                gap: 0.5rem 0;
                dt,
                dd {
                    padding-top: 0;
                    @include typography.body;
                    font-style: normal;
                }
                dd + dt,
                dd + dt + dd {
                    margin-top: 0;
                    padding-top: 0.5rem;
                    border-top: 1px solid var(--tabs-bottom);
                }
            }
        }
    }
}

section#document-list,
main.document {
    // description content
    .description {
        p {
            white-space: pre-line;
        }
    }
}

// RTL tweaks
html[dir="rtl"] main.document section.content-stats ul {
    margin-right: 1.25rem;
    margin-left: 0;
    li + li {
        border-left: none;
        border-right: 1px solid var(--disabled);
        padding-left: 0;
        padding-right: 1rem;
        @include breakpoints.for-tablet-landscape-up {
            padding-left: 0;
            padding-right: 1.5rem;
        }
    }
}

// Hebrew variant
html[lang="he"] main.document {
    .container section.description h3 {
        // "Description" label is always translated
        @include typography.body-he;
    }
    .container dl.metadata-list,
    .container section.extra-metadata,
    .container dl.metadata-list dt,
    .container dl.metadata-list dd,
    dl.metadata-list.tertiary dt#permalink {
        @include typography.meta-he;
    }
    dl *[lang="en"] {
        // handle any dt/dd in English
        @include typography.meta;
    }
}

// Arabic variant
html[lang="ar"] main.document {
    .container section.description h3 {
        // "Description" label is always translated
        @include typography.body-ar;
    }
    .container dl.metadata-list,
    .container dl.metadata-list dt,
    .container dl.metadata-list dd,
    .container section.extra-metadata,
    dl.metadata-list.tertiary dt#permalink {
        @include typography.meta-ar;
    }
    dl *[lang="en"] {
        // handle any dt/dd in English
        @include typography.meta;
    }
}
