// -----------------------------------------------------------------------------
// Visual labels for concepts, themes & other document metadata.
// -----------------------------------------------------------------------------

@use "../base/breakpoints";
@use "../base/typography";

.tags {
    line-height: 2.45rem;
}
.tags li {
    display: inline-block;
    a {
        font-size: typography.$text-size-sm;
        @include breakpoints.for-tablet-landscape-up {
            font-size: typography.$text-size-md;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: lowercase;
        border-radius: 5px;
        background-color: var(--tag-bg);
        transition: background-color 0.15s ease-in-out;
        &:hover {
            background-color: var(--tag-bg-hover);
        }
        color: var(--on-background-light);
        padding: 0 14px;
        min-width: 34px; // 48px - 14px for mobile touch target
        min-height: 28px;
        text-decoration: none;
        line-height: 1;
    }
    // spacing between tags
    margin-right: 6px;
    &:last-of-type {
        margin-right: 0;
    }
}

// "(+ N more)" after a long list of tags
.tags li.more {
    background-color: transparent;
    color: var(--on-background-light-alt);
    &::before {
        content: "";
    }
}

// tweaks for RTL tags for hebrew, arabic
html[dir="rtl"] .tags li {
    // spacing between tags
    &:last-of-type {
        margin-right: 6px;
    }
    &:first-of-type {
        margin-right: 0;
    }
}
