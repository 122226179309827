// -----------------------------------------------------------------------------
// Tabbed navigation that appears in the header of some pages.
// -----------------------------------------------------------------------------

@use "../base/colors";
@use "../base/breakpoints";
@use "../base/spacing";
@use "../base/typography";

nav#tabs {
    max-width: none;
    width: 100%;
    padding: 0 1rem 0;
    // hide tabs on mobile
    .tabs {
        display: none;
    }
    // show <select> on mobile instead
    .mobile-select {
        max-width: 100vw;
        display: block;
        width: 100%;
        height: 2.5rem;
        @include typography.meta;
        background-color: var(--background-light);
        color: var(--on-background);
        border-radius: 5px;
        border: 1px solid var(--background-gray);
        padding: 0 1rem;
    }
    @include breakpoints.for-tablet-landscape-up {
        box-sizing: content-box;
        max-width: 896px;
        .tabs {
            display: grid;
        }
        .mobile-select {
            display: none;
        }
    }
}
.tabs {
    display: grid;
    // on mobile, ensure third tab has enough space
    grid-template-columns: 0.9fr 0.9fr 1.2fr;
    @include breakpoints.for-tablet-landscape-up {
        grid-template-columns: 33% 33% 1fr;
    }
    align-content: stretch;
    justify-content: center;
    margin: 0 auto;
    max-width: none;
    width: 100%;
    @include typography.nav-link;
    // Tabs styling
    a,
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
        padding-bottom: spacing.$spacing-xs;
        border-bottom: 4px solid var(--tabs-bottom);
        @include breakpoints.for-tablet-landscape-up {
            padding-left: 5.5rem;
            padding-right: 5.5rem;
        }
    }
    // Link-specific tabs styling
    a {
        text-decoration: none;
        position: relative;
        /* use ::after for focus outline */
        &::after {
            content: "";
            width: 80%;
            height: 80%;
            position: absolute;
        }
        &:focus-visible {
            outline: none;
        }
        &:focus-visible::after {
            outline: 0.1rem solid var(--focus);
        }
    }
    span[disabled] {
        color: var(--disabled);
    }
    a:hover {
        @include breakpoints.for-tablet-landscape-up {
            border-bottom-color: var(--tabs-bottom-hover);
        }
    }
    a[aria-current="page"] {
        @include typography.nav-bold;
        border-bottom-color: var(--secondary);
        // Don't show hover color on current page tab
        &:hover {
            border-bottom-color: var(--secondary);
        }
    }
}
