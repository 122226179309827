// -----------------------------------------------------------------------------
// Accessibility helpers for showing/hiding content to different users.
// -----------------------------------------------------------------------------

// hide content for all but screen readers
@mixin sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
}

// hide content for all but screen readers, but allow focus by keyboard
// and display content when focused
@mixin sr-only-focusable {
    @include sr-only;
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
}

.sr-only {
    @include sr-only;
}

.sr-only-focusable {
    @include sr-only-focusable;
}
