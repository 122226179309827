// -----------------------------------------------------------------------------
// Bibliographic entry for scholarship on Geniza materials.
// -----------------------------------------------------------------------------

@use "../base/breakpoints";
@use "../base/colors";
@use "../base/spacing";
@use "../base/typography";

.citation {
    counter-increment: search-counter;
    padding: 0 0 spacing.$spacing-md spacing.$spacing-xl;
    margin-left: -#{spacing.$spacing-lg};
    @include breakpoints.for-tablet-landscape-up {
        padding-left: 1.625rem;
        margin-left: 0;
    }
    @include typography.body;
    a {
        @include typography.link;
    }
    & + .citation {
        padding-top: spacing.$spacing-md;
        border-top: 0.1rem solid colors.$gray;
    }
    &::before {
        content: counter(search-counter);
        margin-left: -1.625rem;
        text-align: left;
        float: left;
        @include typography.body-bold;
    }
    ul.locations {
        margin-top: 0.5rem;
        li {
            @include typography.body-bold;
            word-break: break-all;
        }
    }
    ul.relations {
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;
        margin-top: 1rem;
        li {
            display: flex;
            align-items: center;
            height: 2rem;
            padding: 0 spacing.$spacing-md;
            border-radius: 5px;
            background-color: var(--background-gray);
        }
    }
    div.unpublished {
        margin-top: spacing.$spacing-3xs;
        @include typography.unpublished;
        color: var(--on-background-alt);
    }
}

// RTL overrides
html[dir="rtl"] .citation {
    padding: 0 spacing.$spacing-xl spacing.$spacing-md 0;
    margin-right: -#{spacing.$spacing-lg};
    margin-left: 0;
    @include breakpoints.for-tablet-landscape-up {
        padding-right: 1.625rem;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
    }
    &::before {
        margin-left: 0;
        margin-right: -1.625rem;
        text-align: right;
        float: right;
    }
    & + .citation {
        padding-top: spacing.$spacing-md;
    }
}

// Hebrew variant
html[lang="he"] .citation {
    @include typography.body-he;
    *[lang="en"] {
        @include typography.body;
    }
    ul.relations li:not([lang="en"]) {
        @include typography.meta-he;
    }
}

// Arabic variant
html[lang="ar"] .citation {
    @include typography.body-ar;
    *[lang="en"] {
        @include typography.body;
    }
    ul.relations li:not([lang="en"]) {
        @include typography.meta-ar;
    }
}
