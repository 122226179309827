// -----------------------------------------------------------------------------
// Document scholarship records page.
// -----------------------------------------------------------------------------

@use "../base/breakpoints";
@use "../base/spacing";

main.scholarship {
    max-width: 100vw;
    @include breakpoints.for-tablet-landscape-up {
        .container,
        .container * {
            max-width: calc(896px + 2rem);
        }
    }
    // Primary container
    div.container {
        display: flex;
        flex-direction: column;
        padding: 0 spacing.$spacing-sm spacing.$spacing-md;
        @include breakpoints.for-tablet-landscape-up {
            padding: 0 spacing.$spacing-md spacing.$spacing-md;
        }
    }
    ol {
        counter-reset: search-counter;
        margin: 3rem 0 spacing.$spacing-xl spacing.$spacing-lg;
        @include breakpoints.for-tablet-landscape-up {
            margin: calc(2rem + 10px) 0 0;
        }
    }
}

// RTL overrides
html[dir="rtl"] main.scholarship {
    ol {
        margin: 3rem spacing.$spacing-lg spacing.$spacing-xl 0;
        @include breakpoints.for-tablet-landscape-up {
            margin: calc(2rem + 10px) 0 0;
        }
    }
}
