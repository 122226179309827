// -----------------------------------------------------------------------------
// Person list/browse page.
// -----------------------------------------------------------------------------

@use "../base/a11y";
@use "../base/breakpoints";
@use "../base/colors";
@use "../base/fonts";
@use "../base/spacing";
@use "../base/typography";

// TODO: standardize typography here once all redesigns are implemented

main.people {
    // header row
    .topheader-row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 0.75rem;
        @include breakpoints.for-tablet-landscape-up {
            gap: 2rem;
        }
        fieldset#query {
            flex-grow: 1;
            margin-right: 5.75rem;
            @include breakpoints.for-tablet-landscape-up {
                margin-left: 8rem;
                margin-right: 0;
            }
        }
        label#people-view-switcher {
            display: none;
            @include breakpoints.for-tablet-landscape-up {
                display: flex;
            }
            cursor: pointer;
            flex-flow: row nowrap;
            align-items: center;
            background-color: var(--background-gray);
            border-radius: calc(53px / 2);
            input {
                @include a11y.sr-only;
            }
            i::before {
                background-color: var(--background-gray);
                border: 3px solid var(--background-gray);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 53px;
                height: 53px;
                border-width: 4px;
                border-radius: calc(53px / 2);
                font-size: 1.5rem;
                text-decoration: none;
            }
            input:not(:checked) ~ i#grid-view::before,
            input:checked ~ i#list-view::before {
                background-color: var(--selected-language);
                border-color: var(--filter-active);
            }
        }
    }
    // Primary container
    section#person-list {
        width: 100%;
        h2 {
            @include breakpoints.for-tablet-landscape-up {
                font-size: typography.$text-size-2xl;
            }
        }
        table {
            // default view: grid
            display: grid;
            gap: 1rem;
            // 1 column on mobile, 3 column on desktop
            grid-template-columns: 1fr;
            @include breakpoints.for-tablet-landscape-up {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
            margin-top: spacing.$spacing-sm;
            width: 100%;
            tbody {
                // required if we want to use display: grid in table
                display: contents;
            }
            thead {
                // hide the headers on grid
                display: none;
            }
            th {
                text-align: left;
                font-size: typography.$text-size-md;
                padding: 0 spacing.$spacing-xs;
            }
            th:first-of-type {
                padding-left: spacing.$spacing-md;
            }
            th:last-of-type {
                padding-right: spacing.$spacing-md;
            }
            th.related {
                text-align: center;
                min-width: 2rem;
                &::before {
                    @include typography.icon-button-sm;
                }
                &.documents::before {
                    content: "\edf2";
                }
                &.people::before {
                    content: "\f346";
                }
                &.places::before {
                    content: "\f20d";
                }
                &:last-of-type {
                    min-width: 3rem;
                }
            }
            th.dates,
            td.dates {
                max-width: 10rem;
            }
            tbody tr {
                background-color: var(--background-light);
                max-width: none;
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                grid-template-areas:
                    "name name name"
                    "gender gender gender"
                    "date date date"
                    "role role role"
                    "desc desc desc"
                    "tags tags tags"
                    "reldocs relpeople relplaces";
                @include breakpoints.for-tablet-landscape-up {
                    display: flex;
                    flex-flow: column;
                    gap: 0.25rem;
                }
                padding: 1rem;
                border-radius: 5px;
                border: 1px solid var(--background-gray);
                td.name {
                    grid-area: name;
                    font-family: fonts.$primary-bold;
                    font-weight: bold;
                    a {
                        font-family: fonts.$primary-bold;
                        font-weight: bold;
                    }
                    @include breakpoints.for-tablet-landscape-up {
                        font-size: typography.$text-size-xl;
                    }
                }
                td.gender {
                    grid-area: gender;
                }
                td.dates {
                    grid-area: date;
                }
                td.role {
                    grid-area: role;
                }
                td.description {
                    grid-area: desc;
                    border-bottom: 1px solid var(--disabled);
                    padding-bottom: 0.625rem;
                    margin-bottom: 0.625rem;
                    font-size: typography.$text-size-sm;
                    @include breakpoints.for-tablet-landscape-up {
                        flex: 1 0 auto;
                        margin-top: 0.375rem;
                    }
                }
                td.tags:not(:empty) {
                    grid-area: tags;
                    border-bottom: 1px solid var(--disabled);
                    padding-bottom: 0.625rem;
                    margin-bottom: 0.625rem;
                }
                td.related.documents {
                    grid-area: reldocs;
                }
                td.related.people {
                    grid-area: relpeople;
                }
                td.related.places {
                    grid-area: relplaces;
                }
                td.related {
                    display: flex;
                    flex-flow: row;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    gap: 0.5rem;
                    & + td.related {
                        border-left: 1px solid var(--disabled);
                    }
                    @include breakpoints.for-tablet-landscape-up {
                        justify-content: space-between;
                        & + td.related {
                            border-left: none;
                        }
                    }
                }
            }
            th.related,
            td.related {
                .label {
                    display: none;
                }
                &::before,
                .label::before {
                    @include typography.icon-button-sm;
                }
                &.documents::before,
                &.documents > .label::before {
                    content: "\edf2";
                }
                &.people::before,
                &.people > .label::before {
                    content: "\f346";
                }
                &.places::before,
                &.places > .label::before {
                    content: "\f20d";
                }
                @include breakpoints.for-tablet-landscape-up {
                    &::before {
                        display: none;
                    }
                    .label {
                        display: flex;
                        flex-flow: row nowrap;
                        align-items: center;
                        gap: 0.375rem;
                        @include typography.body-italic;
                        &::before {
                            font-style: normal;
                        }
                    }
                }
            }
        }
    }
    .topheader-row:has(input#switcher:not(:checked))
        ~ section#person-list
        table {
        td.other-names {
            font-size: typography.$text-size-md;
            color: var(--date-placeholder);
            span.name {
                font-weight: 700;
            }
            margin-bottom: 0.25rem;
            &:empty {
                display: none;
            }
        }
        td.tags:not(:empty) {
            border-bottom: 1px solid var(--disabled);
            padding-bottom: 0.625rem;
            margin-bottom: 0.625rem;
            @include breakpoints.for-tablet-landscape-up {
                ul {
                    margin-top: -0.25rem;
                }
            }
        }
    }
    @include breakpoints.for-tablet-landscape-up {
        .topheader-row:has(input#switcher:checked) ~ section#person-list table {
            display: table;
            border-collapse: separate;
            border-spacing: 0 spacing.$spacing-xs;
            thead {
                display: contents;
            }
            th.description,
            td.description {
                // description only on grid
                display: none;
            }
            tbody tr {
                display: table-row;
                padding: 0;
                border-radius: 0;
                border: none;
                td {
                    padding: spacing.$spacing-md spacing.$spacing-xs;
                    font-size: typography.$text-size-lg;
                    border-top: 1px solid var(--background-gray);
                    border-bottom: 1px solid var(--background-gray);
                    vertical-align: middle;
                    max-width: 17rem;
                    &.name {
                        padding-left: spacing.$spacing-md;
                        border-left: 1px solid var(--background-gray);
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }
                    &.other-names span.aka {
                        display: none;
                    }
                    &:last-of-type {
                        padding-right: spacing.$spacing-md;
                        min-width: 3rem;
                        border-right: 1px solid var(--background-gray);
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                    &.related {
                        display: table-cell;
                        min-width: 2rem;
                        &:last-of-type {
                            min-width: 3rem;
                        }
                        & + td.related {
                            border-left: none;
                        }
                        &::before {
                            display: none;
                        }
                        .label {
                            display: none;
                        }
                    }
                    &.tags:not(:empty) {
                        padding: 0;
                    }
                }
                & + tr {
                    margin-top: 0;
                }
            }
        }
    }
    .pagination-container {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 1rem;
        nav.pagination {
            margin: 0 auto;
        }
        @include breakpoints.for-tablet-landscape-up {
            padding: 1rem 1.5rem;
            border-width: 1px 0px 1px 0px;
            border-style: solid;
            border-color: var(--background-gray);
            font-size: typography.$text-size-md;
            nav.pagination {
                margin: 0;
                a,
                span {
                    font-size: typography.$text-size-md;
                }
                a {
                    margin-bottom: -1px;
                }
                // button icons
                .prev::before {
                    margin: -1px spacing.$spacing-xs 0 0;
                    font-family: "Phosphor";
                    content: "\f0c3"; // phosphor caret-left icon
                }
                .next::after {
                    margin: -1px 0 0 spacing.$spacing-xs;
                    font-family: "Phosphor";
                    content: "\f0c4"; // phosphor caret-right icon
                }
            }
        }
        div {
            display: none;
            @include breakpoints.for-tablet-landscape-up {
                display: block;
            }
        }
    }
}

// RTL overrides
html[dir="rtl"] main.people {
    // pagination
    nav.pagination {
        // Hebrew and Arabic reversed arrows
        .prev::before {
            margin: 0.1rem 0 0 spacing.$spacing-xs;
            content: "\f0c4"; // phosphor caret-right icon
        }
        .next::after {
            margin: 0.1rem spacing.$spacing-xs 0 0;
            content: "\f0c3"; // phosphor caret-left icon
        }
    }
    section#person-list table {
        th:not(.related) {
            text-align: right;
        }
        th:first-of-type {
            padding-right: spacing.$spacing-md;
            padding-left: 0;
        }
        th:last-of-type {
            padding-left: spacing.$spacing-md;
            padding-right: 0;
        }
        tbody tr {
            td:first-of-type {
                padding-right: spacing.$spacing-md;
                padding-left: 0;
                border-right: 1px solid var(--background-gray);
                border-left: none;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            td:last-of-type {
                padding-left: spacing.$spacing-md;
                padding-right: 0;
                border-left: 1px solid var(--background-gray);
                border-right: none;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}
