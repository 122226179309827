// -----------------------------------------------------------------------------
// Breakpoint mixins for various devices.
// -----------------------------------------------------------------------------

@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) {
        @content;
    }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-xl-up {
    @media (min-width: 1440px) {
        @content;
    }
}
